import { createContext, useEffect, useState } from "react";
import axios from 'axios';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState();

    useEffect(() => {
        const userToken = localStorage.getItem("user_token");

        if (userToken) {
            const parsedToken = JSON.parse(userToken);
            setUser(parsedToken);
        }
    }, []);

    const login = (id, password) => {
        return axios.post("https://portal.jc7transportes.com.br/api/login", { id, password })
            .then(response => {
                const { data } = response;
                if (data.error) {
                    return data.error;
                } else {
                    const { token, nome, departamento } = data;
                    localStorage.setItem("user_token", JSON.stringify({ id, token, nome, departamento }));
                    setUser({ id, nome, departamento });
                    return null;
                }
            })
            .catch(error => {
                return "Erro ao conectar ao servidor. Tente novamente.";
            });
    };
    

    const logout = () => {
        setUser(null);
        localStorage.removeItem("user_token");
    };

    return (
        <AuthContext.Provider
            value={{ user, signed: !!user, login, logout }}
        >
            {children}
        </AuthContext.Provider>
    );
};
