import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./BigBag.module.css";
import Input from "../../../components/Input";
import SubmitButton from "../../../components/Button";
import Axios from "axios";
import InputMask from "react-input-mask";
import { useNavigate } from 'react-router-dom';

import { SnackbarProvider, useSnackbar } from "notistack";

const BigBag = () => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [pesoBigBag, setPesoBigBag] = useState("");
  const [bigbagsLista, setBigBagsLista] = useState([]);

  // Função para mostrar alertas
  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant: variant });
  };

  // Função para enviar dados do cliente
  const cadastrarBigBag = async () => {
    if (!pesoBigBag) {
      showAlert("Preencha todos os campos", "error");
      return;
    }
  
    try {
      const response = await Axios.post(
        "https://portal.jc7transportes.com.br/api/bigbag/cadastrar",
        {
          peso_big_bag: pesoBigBag
        }
      );
  
      showAlert("Peso Big Bag cadastrado com sucesso!", "success");
      // Aguarde 2 segundos (2000 milissegundos) antes de recarregar a página
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Tempo em milissegundos (2 segundos neste exemplo)
    } catch (error) {
      console.error("Erro ao cadastrar o peso do Big Bag:", error);
      showAlert("Erro ao cadastrar o peso Big Bag", "error");
    }
  };

  useEffect(() => {
    Axios.get("https://portal.jc7transportes.com.br/api/pesagemfinal/bigbag")
      .then((response) => {
        setBigBagsLista(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados: ", error);
        showAlert("Erro ao buscar dados", "error");
      });
  }, []);

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
        <div className={style.nav}>
                    <div className={style.navbar} onClick={() => navigate("/administrador")}>
                            Administrador
                        </div>
                        <div className={style.navbar} onClick={() => navigate("/AdministradorPage/Transportadora")}>
                            Transportadora +
                        </div>
                        <div className={style.navbar} onClick={() => navigate("/AdministradorPage/Cliente")}>
                            Cliente +
                        </div>
                        <div className={style.active}>
                            Big Bag +
                        </div>
                        <div className={style.navbar} onClick={() => navigate("/AdministradorPage/Destino")}>
                            Destino +
                        </div>
                    </div>
          <div className={style.columns}>
            
            <div className={style.quantidade}>
              <label>Peso BIG BAG</label>
              <input
                type="number"
                id="nomeMotivado"
                onChange={(e) => setPesoBigBag(e.target.value.toUpperCase())}
              />
            </div>

            <div className={style.submitButton}>
              <SubmitButton text="Cadastrar" onClick={cadastrarBigBag} />
            </div>
          </div>

          <table className={style.table}>
            <thead>
              <tr>
                <th>Código Big Bag</th>
                <th>Peso Big Bag (KG)</th>
              </tr>
            </thead>
            <tbody>
              {bigbagsLista.map((bigbag, index) => (
                <tr key={index}>
                  <td>{bigbag.COD_BIG_BAG}</td>
                  <td>{bigbag.PESO_BIG_BAG} KG</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <BigBag />
    </SnackbarProvider>
  );
}
