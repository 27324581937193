import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Brackground from "../../components/Background";
import Container from "../../components/Container";
import Header from "../../components/Header";
import Axios from "axios";
import style from "./Relatorios.module.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Periodo from '@mui/material/Dialog';
import { SnackbarProvider, useSnackbar } from 'notistack';
import AnchorIcon from '@mui/icons-material/Anchor'; // Substitua 'Anchor' pelo ícone que deseja usar



const Relatorios = () => {
  useEffect(() => {
    getOp();
  }, []);

  const navigate = useNavigate();
  const [naviosList, setNaviosList] = useState([]);
  const [selectedNavio, setSelectedNavio] = useState("");
  const [periodo, setPeriodo] = useState([]);
  const [list, setList] = useState([])

  const getOp = () => {
    Axios.get('https://portal.jc7transportes.com.br/api/relatorios/operacoes').then((response) => {
      setNaviosList(response.data);
    });
  }

  const getPeriodo = (id) => {
    Axios.get(`https://portal.jc7transportes.com.br/api/periodos/gerais/${id}`).then((response) => {
      setPeriodo(response.data);
    });
  }

  const onSelectNavio = (e) => {
    const selectedValue = e.target.value;
    setSelectedNavio(selectedValue);
    getPeriodo(selectedValue);
  }

  const DetalhesNavio = (id, status, nome) => {
    navigate(`/relatorios/${id}`)

 setList(nome)
};

  const redirectToRelatorioPeriodo = () => {
    if (selectedNavio) {
      navigate(`/relatorios/${selectedNavio}`);
    } else {
      showAlert("Por favor, selecione um navio primeiro", "error");
    }
  }

  const { enqueueSnackbar } = useSnackbar();
  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant: variant });
  }

  return (
    <>
      <Navbar relatorios />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div className={style.active}>
              RELATÓRIOS
          </div>
          </div>
          <div className={style.table}>
      <div className={style.table_outer_container}>
        <div className={style.table_container}>
          {naviosList
            .filter(val => val.STATUS_OPERACAO === "OPERANDO")
            .map((val, key) => (
              <div
                className={style.table_item}
                key={key}
                onClick={() => [DetalhesNavio(val.COD_OPERACAO, val.STATUS_OPERACAO, val.NOME_NAVIO), getPeriodo(val.COD_OPERACAO)]}>            
                <div className={style.detalheNavio}><i className="fas fa-ship"></i> {val.NOME_NAVIO || "-"}</div>
                <div className={style.detalheNavio}>IMO: {val.IMO_NAVIO || "-"}</div>
                <div className={style.detalheNavio}>ATRACAÇÃO: {moment(val.ATRACACAO).format("DD/MM/YYYY") || "-"}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
          <div className={style.dropdownContainer}>
            <select
              className={style.navioDropdown}
              value={selectedNavio}
              onChange={onSelectNavio}
            >
              <option value="">Selecione um Navio</option>
              {naviosList.map((val, key) => (
                <option key={key} value={val.COD_OPERACAO}>{val.NOME_NAVIO || "-"}</option>
              ))}
            </select>
            <button className={style.btnRelatorio} onClick={redirectToRelatorioPeriodo}>
              Gerar Relatório
            </button>
          </div>

        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={3500}>
      <Relatorios />
    </SnackbarProvider>
  );
}
