import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Background from "../../components/Background";
import Container from "../../components/Container";
import Header from "../../components/Header";
import style from "./Cameras.module.css";
import { useParams } from "react-router-dom";

// Componente para o Modal
const Modal = ({ src, label, onClose }) => {
  return (
    <div className={style.modalOverlay} onClick={onClose}>
      <div className={style.modalContent} onClick={(e) => e.stopPropagation()}>
        <iframe
          src={src}
          frameBorder="0"
          allowFullScreen
          scrolling="no"
          width="100%"
          height="500"
        ></iframe>
        <p>{label}</p>
        <button className={style.closeButton} onClick={onClose}>Fechar</button>
      </div>
    </div>
  );
};

const Cameras = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("Pátio 2");  // Estado para a tab ativa
  const [isModalOpen, setIsModalOpen] = useState(false);  // Estado para o modal
  const [selectedCamera, setSelectedCamera] = useState(null); // Estado para a câmera selecionada

  // Função para renderizar os iframes de acordo com a tab ativa
  const renderIframe = () => {
    // Configuração dos iframes para Pátio 2
    const iframesMoega01 = [
      { key: "moega01-1", src: "https://player.twitch.tv/?channel=camera01moega&parent=portal.jc7transportes.com.br", label: "1 - Entrada Externa" },
      { key: "moega01-2", src: "https://player.twitch.tv/?channel=camera02moega&parent=portal.jc7transportes.com.br", label: "2 - Portaria" },
      { key: "moega01-3", src: "https://player.twitch.tv/?channel=camera03moega&parent=portal.jc7transportes.com.br", label: "3 - Entrada Interna" },
      { key: "moega01-4", src: "https://player.twitch.tv/?channel=camera04moega&parent=portal.jc7transportes.com.br", label: "4 - Armazém" },
      { key: "moega01-5", src: "https://player.twitch.tv/?channel=camera05moega&parent=portal.jc7transportes.com.br", label: "5 - Perímetro Lateral" },
      { key: "moega01-6", src: "https://player.twitch.tv/?channel=moega01setportcam06&parent=portal.jc7transportes.com.br", label: "6 - Perímetro Interno" },
      { key: "moega01-7", src: "https://player.twitch.tv/?channel=moega01setportcam06&parent=portal.jc7transportes.com.br", label: "7 - Balança 01" },
      { key: "moega01-8", src: "https://player.twitch.tv/?channel=moega01setportcam06&parent=portal.jc7transportes.com.br", label: "8 - Balança 02" }
    ];

    switch (activeTab) {
      case "Pátio 2":
        return (
          <div className={style.iframeGrid}>
            {iframesMoega01.map((iframe) => (
              <div
                key={iframe.key}
                className={style.iframeWrapper}
                onClick={() => openModal(iframe)}
              >
                <iframe
                  src={iframe.src}
                  frameBorder="0"
                  allowFullScreen
                  scrolling="no"
                  height="200"
                  width="100%"
                ></iframe>
                <p>{iframe.label}</p>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  // Função para abrir o modal
  const openModal = (iframe) => {
    setSelectedCamera(iframe);
    setIsModalOpen(true);
  };

  // Função para fechar o modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCamera(null);
  };

  return (
    <>
      <Navbar />
      <Header />
      <Background />
      <Container>
        <br />
        <div className={style.content}>
          <div className={style.tabContainer}>
            <button
              className={`${style.tabButton} ${activeTab === "Pátio 2" ? style.active : ""}`}
              onClick={() => setActiveTab("Pátio 2")}
            >
              Pátio 2
            </button>
          </div>

          <div className={style.iframeContainer}>{renderIframe()}</div>

          {/* Exibir Modal se estiver aberto */}
          {isModalOpen && selectedCamera && (
            <Modal
              src={selectedCamera.src}
              label={selectedCamera.label}
              onClose={closeModal}
            />
          )}
        </div>
      </Container>
    </>
  );
};

export default Cameras;
