import React, { useState } from "react";
import Axios from "axios";
import { useNavigate } from 'react-router-dom';
import Navbar from "../../../components/Navbar";
import Background from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import SubmitButton from "../../../components/Button";
import style from "./Administrador.module.css";

const Administrador = () => {
    const navigate = useNavigate();
    const [carregamentoId, setCarregamentoId] = useState('');
    const [carregamentoInfo, setCarregamentoInfo] = useState([]);
    const [erro, setErro] = useState('');
    const [editMode, setEditMode] = useState(null); // Armazena o ID do carregamento em edição
    const [editedData, setEditedData] = useState({});
    const [editedPesoCarregado, setEditedPesoCarregado] = useState('');
    const [editModeNumeroPesoNF, setEditModeNumeroPesoNF] = useState(null);
    const [editedNumeroPesoNF, setEditedNumeroPesoNF] = useState('');
    const [editModePesoNF, setEditModePesoNF] = useState(null);
    const [editedPesoNF, setEditedPesoNF] = useState('');
    const [editModePesoBruto, setEditModePesoBruto] = useState(null);
    const [editedPesoBruto, setEditedPesoBruto] = useState('');
    const [editModePesoTara, setEditModePesoTara] = useState(null);
    const [editedPesoTara, setEditedPesoTara] = useState('');
    const [editModePlacaCavalo, setEditModePlacaCavalo] = useState(null);
    const [editedPlacaCavalo, setEditedPlacaCavalo] = useState('');
    const [editModePlacaCarreta, setEditModePlacaCarreta] = useState(null);
    const [editedPlacaCarreta, setEditedPlacaCarreta] = useState('');
    const [editModePlacaCarreta2, setEditModePlacaCarreta2] = useState(null);
    const [editedPlacaCarreta2, setEditedPlacaCarreta2] = useState('');
    const [editModePlacaCarreta3, setEditModePlacaCarreta3] = useState(null);
    const [editedPlacaCarreta3, setEditedPlacaCarreta3] = useState('');

    const ShowAlert = (message) => {
        alert(message);
    };

    const buscarCarregamento = () => {
        Axios.get(`https://portal.jc7transportes.com.br/api/administrador/carregamento/${carregamentoId}`)
            .then(response => {
                setCarregamentoInfo(response.data);
                setErro('');
            })
            .catch(error => {
                console.error('Erro ao buscar carregamento', error);
                setErro('Carregamento não encontrado ou erro na consulta');
                setCarregamentoInfo([]);
            });
    };

    const handleEdit = (rowData) => {
        setEditedPesoCarregado(rowData.PESO_CARREGADO.toString());
        setEditMode(rowData.ID_CARREGAMENTO);
        setEditedData({ ...rowData });
    };

    const handleCancelEdit = () => {
        setEditMode(null);
        setEditedData({});
        setEditedPesoCarregado('');
    };

    const handleSave = () => {
        const updatedData = { PESO_CARREGADO: parseFloat(editedPesoCarregado) };

        Axios.put(`https://portal.jc7transportes.com.br/api/administrador/updatecarregado/${carregamentoId}`, updatedData)
            .then(response => {
                buscarCarregamento();
                setEditMode(null);
                setEditedData({});
                setEditedPesoCarregado('');
                console.log('Peso do carregamento atualizado com sucesso');
                ShowAlert('Peso alterado com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao salvar carregamento', error);
            });
    };

    const handleEditNumeroPesoNF = (rowData) => {
        const initialNumber = rowData.NUMERO_NOTA_FISCAL === null ? '' : rowData.NUMERO_NOTA_FISCAL.toString();
        setEditedNumeroPesoNF(initialNumber);
        setEditModeNumeroPesoNF(rowData.ID_CARREGAMENTO);
        setEditedData({ ...rowData });
    };

    const handleCancelEditNumeroPesoNF = () => {
        setEditModeNumeroPesoNF(null);
        setEditedData({});
        setEditedNumeroPesoNF('');
    };

    const handleSaveNumeroPesoNF = () => {
        const updatedDataNumeroNF = { NUMERO_NOTA_FISCAL: parseFloat(editedNumeroPesoNF) };

        Axios.put(`https://portal.jc7transportes.com.br/api/administrador/updatenumeropesonf/${carregamentoId}`, updatedDataNumeroNF)
            .then(response => {
                buscarCarregamento();
                setEditModeNumeroPesoNF(null);
                setEditedData({});
                setEditedNumeroPesoNF('');
                console.log('Numero do Peso Nota Fiscal atualizado com sucesso');
                ShowAlert('Numero do Peso Nota Fiscal alterado com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao salvar o Numero do Peso Nota Fiscal', error);
            });
    };

    const handleEditPesoNF = (rowData) => {
        setEditedPesoNF(rowData.PESO_NOTA_FISCAL.toString());
        setEditModePesoNF(rowData.ID_CARREGAMENTO);
        setEditedData({ ...rowData });
    };

    const handleCancelEditPesoNF = () => {
        setEditModePesoNF(null);
        setEditedData({});
        setEditedPesoNF('');
    };

    const handleSavePesoNF = () => {
        const updatedDataNF = { PESO_NOTA_FISCAL: parseFloat(editedPesoNF) };

        Axios.put(`https://portal.jc7transportes.com.br/api/administrador/updatepesonf/${carregamentoId}`, updatedDataNF)
            .then(response => {
                buscarCarregamento();
                setEditModePesoNF(null);
                setEditedData({});
                setEditedPesoNF('');
                console.log('Peso Nota Fiscal atualizado com sucesso');
                ShowAlert('Peso Nota Fiscal alterado com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao salvar Peso Nota Fiscal', error);
            });
    };

    const handleEditPesoBruto = (rowData) => {
        setEditedPesoBruto(rowData.PESO_BRUTO.toString());
        setEditModePesoBruto(rowData.ID_CARREGAMENTO);
        setEditedData({ ...rowData });
    };

    const handleCancelEditPesoBruto = () => {
        setEditModePesoBruto(null);
        setEditedPesoBruto('');
        setEditedData({});
    };

    const handleSavePesoBruto = () => {
        const updatedDataBruto = { PESO_BRUTO: parseFloat(editedPesoBruto) };

        Axios.put(`https://portal.jc7transportes.com.br/api/administrador/updatepesobruto/${carregamentoId}`, updatedDataBruto)
            .then(response => {
                buscarCarregamento();
                setEditModePesoBruto(null);
                setEditedPesoBruto('');
                setEditedData({});
                console.log('Peso Bruto atualizado com sucesso');
                ShowAlert('Peso Bruto alterado com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao salvar Peso Bruto', error);
            });
    };

    const handleEditPesoTara = (rowData) => {
        setEditedPesoTara(rowData.PESO_TARA.toString());
        setEditModePesoTara(rowData.ID_CARREGAMENTO);
    };

    const handleCancelEditPesoTara = () => {
        setEditModePesoTara(null);
        setEditedPesoTara('');
    };

    const handleSavePesoTara = () => {
        const updatedDataTara = { PESO_TARA: parseFloat(editedPesoTara) };

        Axios.put(`https://portal.jc7transportes.com.br/api/administrador/updatepesotara/${carregamentoId}`, updatedDataTara)
            .then(response => {
                buscarCarregamento();
                setEditModePesoTara(null);
                setEditedPesoTara('');
                console.log('Peso Tara atualizado com sucesso');
                ShowAlert('Peso Tara alterado com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao salvar Peso Tara', error);
            });
    };

    const handleEditPlacaCavalo = (rowData) => {
        setEditedPlacaCavalo(rowData.PLACA_CAVALO);
        setEditModePlacaCavalo(rowData.ID_CARREGAMENTO);
    };

    const handleCancelEditPlacaCavalo = () => {
        setEditModePlacaCavalo(null);
        setEditedPlacaCavalo('');
    };

    const handleSavePlacaCavalo = () => {
        const updatedPlacaCavalo = { PLACA_CAVALO: editedPlacaCavalo };

        Axios.put(`https://portal.jc7transportes.com.br/api/administrador/updateplacacavalo/${carregamentoId}`, updatedPlacaCavalo)
            .then(response => {
                buscarCarregamento();
                setEditModePlacaCavalo(null);
                setEditedPlacaCavalo('');
                console.log('Placa Cavalo atualizada com sucesso');
                ShowAlert('Placa Cavalo alterada com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao salvar Placa Cavalo', error);
            });
    };

    const handleEditPlacaCarreta = (rowData) => {
        setEditedPlacaCarreta(rowData.PLACA_CARRETA);
        setEditModePlacaCarreta(rowData.ID_CARREGAMENTO);
    };

    const handleCancelEditPlacaCarreta = () => {
        setEditModePlacaCarreta(null);
        setEditedPlacaCarreta('');
    };

    const handleSavePlacaCarreta = () => {
        const updatedPlacaCarreta = { PLACA_CARRETA: editedPlacaCarreta };

        Axios.put(`https://portal.jc7transportes.com.br/api/administrador/updateplacacarreta/${carregamentoId}`, updatedPlacaCarreta)
            .then(response => {
                buscarCarregamento();
                setEditModePlacaCarreta(null);
                setEditedPlacaCarreta('');
                alert('Placa da carreta atualizada com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao atualizar a placa da carreta', error);
                alert('Erro ao atualizar a placa da carreta');
            });
    };

    const handleEditPlacaCarreta2 = (rowData) => {
        setEditedPlacaCarreta2(rowData.PLACA_CARRETA2);
        setEditModePlacaCarreta2(rowData.ID_CARREGAMENTO);
    };

    const handleCancelEditPlacaCarreta2 = () => {
        setEditModePlacaCarreta2(null);
        setEditedPlacaCarreta2('');
    };

    const handleSavePlacaCarreta2 = () => {
        const updatedPlacaCarreta2 = { PLACA_CARRETA2: editedPlacaCarreta2 };

        Axios.put(`https://portal.jc7transportes.com.br/api/administrador/updateplacacarreta2/${carregamentoId}`, updatedPlacaCarreta2)
            .then(response => {
                buscarCarregamento();
                setEditModePlacaCarreta2(null);
                setEditedPlacaCarreta2('');
                alert('Placa da carreta 2 atualizada com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao atualizar a placa da carreta 2', error);
                alert('Erro ao atualizar a placa da carreta 2');
            });
    };

    const handleEditPlacaCarreta3 = (rowData) => {
        setEditedPlacaCarreta3(rowData.PLACA_CARRETA3);
        setEditModePlacaCarreta3(rowData.ID_CARREGAMENTO);
    };

    const handleCancelEditPlacaCarreta3 = () => {
        setEditModePlacaCarreta3(null);
        setEditedPlacaCarreta3('');
    };

    const handleSavePlacaCarreta3 = () => {
        const updatedPlacaCarreta3 = { PLACA_CARRETA3: editedPlacaCarreta3 };

        Axios.put(`https://portal.jc7transportes.com.br/api/administrador/updateplacacarreta3/${carregamentoId}`, updatedPlacaCarreta3)
            .then(response => {
                buscarCarregamento();
                setEditModePlacaCarreta3(null);
                setEditedPlacaCarreta3('');
                alert('Placa da carreta 3 atualizada com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao atualizar a placa da carreta 3', error);
                alert('Erro ao atualizar a placa da carreta 3');
            });
    };

   const handleDelete = (carregamentoId) => {
        Axios.put(`https://portal.jc7transportes.com.br/api/administrador/updatestatuscarreg/${carregamentoId}`)
            .then(response => {
                buscarCarregamento();
                console.log('Carregamento marcado como excluído com sucesso');
                ShowAlert('Carregamento marcado como excluído com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao marcar carregamento como excluído', error);
                ShowAlert('Erro ao marcar carregamento como excluído!');
            });
    };


    return (
        <>
            <Navbar />
            <Header />
            <Background />
            <Container>
                <div className={style.content}>
                    <div className={style.nav}>
                        <div className={style.active}>
                            Administrador
                        </div>
                        <div className={style.navbar} onClick={() => navigate("/AdministradorPage/Transportadora")}>
                            Transportadora +
                        </div>
                        <div className={style.navbar} onClick={() => navigate("/AdministradorPage/Cliente")}>
                            Cliente +
                        </div>
                        <div className={style.navbar} onClick={() => navigate("/AdministradorPage/BigBag")}>
                            Big Bag +
                        </div>
                        <div className={style.navbar} onClick={() => navigate("/AdministradorPage/Destino")}>
                            Destino +
                        </div>
                    </div>
                    <div className={style.leftColumn2}>
                        <div className={style.leftColumn}>
                            <input
                                type="text"
                                placeholder="Digite o ID do Carregamento"
                                value={carregamentoId}
                                onChange={(e) => setCarregamentoId(e.target.value)}
                                className={style.inputField}
                            />
                            <SubmitButton text="Buscar" onClick={buscarCarregamento} />
                            {erro && <p className={style.error}>{erro}</p>}
                        </div>
                    </div>

                    <div className={style.rightColumn}>
                        {carregamentoInfo.length > 0 && carregamentoInfo.map((info, index) => (
                            <div key={index} className={`${style.card} ${info.STATUS_CARREG === 7 ? style.cardRed : ''}`}>
                                <div className={style.historico}>Histórico do Motorista</div>
                                <div className={style.cardItem}><strong>DI:</strong> {info.REFERENCIA}</div>
                                <div className={style.cardItem}><strong>Navio:</strong> {info.NOME_NAVIO}</div>
                                <div className={style.cardItem}><strong>Motorista:</strong> {info.NOME_MOTORISTA}</div>

                                <div className={style.cardItem}>
                                    <strong>Placa Cavalo:</strong>
                                    {editModePlacaCavalo === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedPlacaCavalo}
                                            onChange={(e) => setEditedPlacaCavalo(e.target.value)}
                                        />
                                    ) : (
                                        info.PLACA_CAVALO
                                    )}
                                    {editModePlacaCavalo === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSavePlacaCavalo}>✅</span>
                                            <span onClick={handleCancelEditPlacaCavalo}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditPlacaCavalo(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}>
                                    <strong>Placa Carreta 1:</strong>
                                    {editModePlacaCarreta === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedPlacaCarreta}
                                            onChange={(e) => setEditedPlacaCarreta(e.target.value)}
                                        />
                                    ) : (
                                        info.PLACA_CARRETA
                                    )}
                                    {editModePlacaCarreta === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSavePlacaCarreta}>✅</span>
                                            <span onClick={handleCancelEditPlacaCarreta}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditPlacaCarreta(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}>
                                    <strong>Placa Carreta 2:</strong>
                                    {editModePlacaCarreta2 === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedPlacaCarreta2}
                                            onChange={(e) => setEditedPlacaCarreta2(e.target.value)}
                                        />
                                    ) : (
                                        info.PLACA_CARRETA2
                                    )}
                                    {editModePlacaCarreta2 === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSavePlacaCarreta2}>✅</span>
                                            <span onClick={handleCancelEditPlacaCarreta2}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditPlacaCarreta2(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}>
                                    <strong>Placa Carreta 3:</strong>
                                    {editModePlacaCarreta3 === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedPlacaCarreta3}
                                            onChange={(e) => setEditedPlacaCarreta3(e.target.value)}
                                        />
                                    ) : (
                                        info.PLACA_CARRETA3
                                    )}
                                    {editModePlacaCarreta3 === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSavePlacaCarreta3}>✅</span>
                                            <span onClick={handleCancelEditPlacaCarreta3}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditPlacaCarreta3(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}><strong>Transportadora:</strong> {info.NOME_REDUZIDO}</div>
                                <div className={style.cardItem}><strong>Pedido:</strong> {info.PEDIDO_MIC}</div>

                                <div className={style.cardItem}><strong>1° Peso (Tara):</strong>
                                    {editModePesoTara === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedPesoTara}
                                            onChange={(e) => setEditedPesoTara(e.target.value)}
                                        />
                                    ) : (
                                        `${Number(info.PESO_TARA).toLocaleString(undefined, { maximumFractionDigits: 3 })} KG`
                                    )}
                                    {editModePesoTara === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSavePesoTara}>✅</span>
                                            <span onClick={handleCancelEditPesoTara}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditPesoTara(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}><strong>2° Peso (Bruto):</strong>
                                    {editModePesoBruto === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedPesoBruto}
                                            onChange={(e) => setEditedPesoBruto(e.target.value)}
                                        />
                                    ) : (
                                        `${Number(info.PESO_BRUTO).toLocaleString(undefined, { maximumFractionDigits: 3 })} KG`
                                    )}
                                    {editModePesoBruto === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSavePesoBruto}>✅</span>
                                            <span onClick={handleCancelEditPesoBruto}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditPesoBruto(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}><strong>Número (Nota Fiscal):</strong>
                                    {editModeNumeroPesoNF === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedNumeroPesoNF}
                                            onChange={(e) => setEditedNumeroPesoNF(e.target.value)}
                                        />
                                    ) : (
                                        `${info.NUMERO_NOTA_FISCAL !== null ? Number(info.NUMERO_NOTA_FISCAL).toLocaleString(undefined, { maximumFractionDigits: 3 }) : 'Insira um número'}`
                                    )}
                                    {editModeNumeroPesoNF === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSaveNumeroPesoNF}>✅</span>
                                            <span onClick={handleCancelEditNumeroPesoNF}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditNumeroPesoNF(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}><strong>Peso Nota Fiscal:</strong>
                                    {editModePesoNF === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedPesoNF}
                                            onChange={(e) => setEditedPesoNF(e.target.value)}
                                        />
                                    ) : (
                                        `${Number(info.PESO_NOTA_FISCAL).toLocaleString(undefined, { maximumFractionDigits: 3 })} KG`
                                    )}
                                    {editModePesoNF === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSavePesoNF}>✅</span>
                                            <span onClick={handleCancelEditPesoNF}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditPesoNF(info)}>✏️</span>
                                    )}
                                </div>

                                {info.STATUS_CARREG !== 7 && (
                                    <div className={style.cardItem}>
                                        <SubmitButton text="Excluir Carregamento" onClick={() => handleDelete(info.ID_CARREGAMENTO)} />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Administrador;
