import React from 'react'
import "./styles.css"

const Brackground = () => {
  return (
    <>
        <div className='feixe1'></div>
    </>
  )
}

export default Brackground