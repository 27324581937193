import React from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./PesagemFinal.module.css";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../components/Input";
import SubmitButton from "../../../components/Button";
import Select from "../../../components/select";
import { useState, useEffect } from "react";
import Axios from "axios";
import { SnackbarProvider, useSnackbar } from 'notistack';
import MaskedInput from "../../../components/InputMask";
import moment from "moment";
import jsPDF from 'jspdf';
import JC7Logo from "./Logo-JC7.png";
import Modal from 'react-modal'; // Certifique-se de instalar react-modal
import modalStyles from "./Modal.module.css"; // Estilos específicos para o modal

const PesagemFinal = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getTipoveiculo()
    getBigBag()
    getOperacoes()
    getOperacao()
    getTransp()
    fetchPlacas(cpf);
  }, [])

  const fetchPlacas = async (cpf) => {
    try {
      const response = await Axios.get(`https://portal.jc7transportes.com.br/api/pesageminicial/historico/${cpf}`);
      if (response.data.length > 0) {
        const { PLACA_CAVALO, PLACA_CARRETA, PLACA_CARRETA2, PLACA_CARRETA3, TIPO_VEICULO } = response.data[0]; // Destructure the response
        setPlacacavalo(PLACA_CAVALO);
        setPlaca1(PLACA_CARRETA || ''); // Set or default to empty string
        setPlaca2(PLACA_CARRETA2 || '');
        setPlaca3(PLACA_CARRETA3 || '');
        setTipoveiculo(TIPO_VEICULO || '');
      }
    } catch (error) {
      console.error('Error fetching vehicle plate data:', error);
    }
  };

  const [tipopesagem, setTipopesagem] = useState('G');
  const [disabled, setDisabled] = useState(false);
  const [navio, setNavio] = useState('');
  const [bruto, setBruto] = useState('');
  const [tara, setTara] = useState('');
  const [qtdabigbag, setQtdaBigBag] = useState('');
  const [pesobigbag, setPesoBigBag] = useState('');
  const [bigbags, setBigBags] = useState([]);
  const [numerolacre, setNumeroLacre] = useState('');
  const [numeroconteiner, setNumeroConteiner] = useState('');

  const [tipoveiculo, setTipoveiculo] = useState('');
  const [tipoveiculos, setTipoveiculos] = useState([])
  const [placacavalo, setPlacacavalo] = useState('');
  const [placa1, setPlaca1] = useState('');
  const [placa2, setPlaca2] = useState(null);
  const [placa3, setPlaca3] = useState(null);
  const [numeronotafiscal, setNumeroNotaFiscal] = useState('');
  const [pesonotafiscal, setPesoNotaFiscal] = useState('');

  const [operacoesList, setOperacoesList] = useState([]);
  const [operacoes, setOperacao] = useState([]);
  const [pedidosByDoc, setPedidosByDoc] = useState([]);
  const [docs, setDocs] = useState([]);
  const [pedidoMic, setPedidoMic] = useState([]);
  const [destino, setDestino] = useState('');

  const [doc, setDoc] = useState('');
  const [porcentagem, setPorcentagem] = useState(null);
  const [saldoTons, setSaldoTons] = useState(null);
  const [transportadora, setTransportadora] = useState([]);
  const [transportadoras, setTransportadoras] = useState([]);
  const [data, setData] = useState('');
  const [tipofluxo, setTipoFluxo] = useState('ENTRADA');
  const [carregamentoDetails, setCarregamentoDetails] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fluxoConteiner, setFluxoConteiner] = useState('');


  const usuario = JSON.parse(localStorage.getItem("user_token")).id;

  const validaPlaca = (placa) => {
    // Expressão regular para validar placas no formato antigo (AAA1234) ou Mercosul (AAA1B23)
    const placaRegex = /^[A-Z]{3}\d{4}$|^[A-Z]{3}\d{1}[A-Z]{1}\d{2}$|^[A-Z]{3}\d{2}[A-Z]{1}\d{1}$/;

    return placaRegex.test(placa);
  };

  const validaDados = async () => {
    // Campos comuns obrigatórios
    if (!doc || !placacavalo || !tipoveiculo || !transportadora || !tipopesagem) {
      showAlert('Preencha todos os campos obrigatórios.', 'error');
      return;
    }

    // Validações de placa
    if (!validaPlaca(placacavalo)) {
      showAlert('Placa do cavalo deve estar no formato correto (Ex: AAA1234 ou AAA1B23)', 'error');
      return;
    }
    if (placa1 && !validaPlaca(placa1) || placa2 && !validaPlaca(placa2) || placa3 && !validaPlaca(placa3)) {
      showAlert('Placas devem estar no formato correto (Ex: AAA1234 ou AAA1B23)', 'error');
      return;
    }

    // Validações específicas para ENTRADA
    if (tipofluxo === 'ENTRADA') {
      if (!bruto || !pesonotafiscal || !pedidoMic) {
        showAlert('Preencha todos os campos obrigatórios para a ENTRADA.', 'error');
        return;
      }
    }

    // Validações específicas para SAÍDA
    if (tipofluxo === 'SAÍDA') {
      if (!tara) {
        showAlert('Preencha todos os campos obrigatórios para a SAÍDA.', 'error');
        return;
      }
    }

    // Continua a verificação de registro em aberto
    try {
      const response = await Axios.get(`https://portal.jc7transportes.com.br/api/pesageminicial/valida/${cpf}/${navio}`);
      const registroEmAberto = response.data;
      if (registroEmAberto.length > 0) {
        const nomeNavio = registroEmAberto[0].NOME_NAVIO;
        showAlert(`Motorista aguardando a segunda pesagem do navio: ${nomeNavio}.`, 'error');
      } else {
        // Se tudo estiver correto, executa a função addPesagem
        addPesagem();
      }
    } catch (error) {
      console.error('Erro ao verificar registro em aberto ou fazer a solicitação:', error);
      showAlert('Ocorreu um erro ao verificar o registro em aberto ou fazer a solicitação.', 'error');
    }
  };



  const validaTecla = (e) => {
    if (e.key === 'Enter') {
      validaDados()
    }
  }

  const validaTipo = (val) => {
    if (val === "G") {
      showAlert('Pesagem a GRANEL selecionada com sucesso!', 'success');
      setDisabled(false);
      setBruto(0);
    }
    else if (val === "B") {
      showAlert('Pesagem em BIG BAG selecionada com sucesso!', 'success');
      setDisabled(false);
      setBruto(0);
    }
    else {
      showAlert('Pesagem em Container selecionada com sucesso!', 'success');
      setBruto(1000);
      setDisabled(true);
    }
  }

  const validaFluxo = (val) => {
    if (val === "SAÍDA") {
      showAlert('Pesagem de SAÍDA selecionada com sucesso!', 'success');
      setDisabled(false);
      setBruto(0);
    }
    else {
      showAlert('Pesagem de ENTRADA selecionada com sucesso!', 'success');
      setBruto(1000);
      setDisabled(true);
    }
  }

  const getOperacao = (idCarregamento, payload) => {
    Axios.get(`https://portal.jc7transportes.com.br/api/periodo/carregamento/${idCarregamento}`)
      .then((response) => {
        console.log(response.data);

        generatePdf({ ...payload, ID_CARREGAMENTO: idCarregamento });
        // Notificação de início do processo
        showAlert('Iniciando a geração do PDF.', 'info');

      })
      .catch((error) => {
        console.error("Erro ao buscar operações:", error);
      });
  }

  const addPesagem = () => {
    const qtdaBigBagValue = qtdabigbag ? parseInt(qtdabigbag, 10) : null;
    const codBigBagValue = pesobigbag ? parseInt(pesobigbag, 10) : null;
    const numeroLacreValue = numerolacre ? numerolacre.trim() : null;
    const numeroConteinerValue = numeroconteiner ? numeroconteiner.trim() : null;
  
    const dataCadastro = getDate(); // Define a data atual no momento do clique
  
    let payload = {
      COD_CARGA: doc,
      COD_OPERACAO: navio,
      QTDA_BIG_BAG: qtdaBigBagValue,
      COD_BIG_BAG: codBigBagValue,
      NUMERO_LACRE: numeroLacreValue,
      NUMERO_CONTEINER: numeroConteinerValue,
      PLACA_CAVALO: placacavalo,
      COD_MOTORISTA: id,
      PLACA_CARRETA: placa1,
      PLACA_CARRETA2: placa2,
      PLACA_CARRETA3: placa3,
      TIPO_VEICULO: tipoveiculo,
      COD_TRANSP: transportadora,
      STATUS_CARREG: '1',
      USUARIO: usuario,
      DATA_CADASTRO: dataCadastro,
      ID_PEDIDO: (tipopesagem === 'C' || tipofluxo === 'SAÍDA') ? null : (pedidoMic || null),
      TIPO_PESAGEM: tipopesagem,
      TIPO_FLUXO: tipofluxo,
    };
  
    // Adiciona FLUXO_CONTEINER apenas se tipopesagem for 'C'
    if (tipopesagem === 'C') {
      payload.FLUXO_CONTEINER = fluxoConteiner;
    }
  
    if (numeronotafiscal && numeronotafiscal.trim() !== '') {
      payload.NUMERO_NOTA_FISCAL = numeronotafiscal;
    }
    if (pesonotafiscal && pesonotafiscal.trim() !== '') {
      payload.PESO_NOTA_FISCAL = parseFloat(pesonotafiscal);
    }
  
    // Adiciona os campos relacionados ao peso e data conforme o tipo de fluxo
    if (tipofluxo === 'SAÍDA') {
      payload.PESO_TARA = tara ? parseFloat(tara) : null;
      payload.DATA_TARA = dataCadastro; // Armazena a data atual em DATA_TARA
      payload.USUARIO_TARA = usuario;
    } else {
      payload.PESO_BRUTO = bruto ? parseFloat(bruto) : null;
      payload.DATA_BRUTO = dataCadastro; // Armazena a data atual em DATA_BRUTO
      payload.USUARIO_BRUTO = usuario;
    }
  
    Axios.post("https://portal.jc7transportes.com.br/api/pesagem/primeirapesagem", payload)
      .then((res) => {
        if (res.data.success) {
          const idCarregamento = res.data.ID_CARREGAMENTO;
          showAlert(`Pesagem cadastrada com sucesso! ID: ${idCarregamento}`, "success");
  
          getCarregamentoDetails(idCarregamento);
        } else {
          showAlert("Erro ao cadastrar a pesagem. Por favor, tente novamente.", "error");
        }
      })
      .catch((error) => {
        console.error("Erro ao cadastrar a pesagem:", error);
        showAlert("Ocorreu um erro ao cadastrar a pesagem.", "error");
      });
  };
  

  const getCarregamentoDetails = (idCarregamento) => {
    Axios.get(`https://portal.jc7transportes.com.br/api/pesagem/carregamento/${idCarregamento}`)
      .then((response) => {
        console.log(response.data); // Verifique os dados aqui
        if (response.data && response.data.length > 0) {
          setCarregamentoDetails(response.data[0]); // Acesse o primeiro item do array
        }
        setModalIsOpen(true); // Abre o modal
      })
      .catch((error) => {
        console.error("Erro ao buscar dados do carregamento:", error);
        showAlert("Erro ao carregar informações do carregamento.", "error");
      });
  };

  const closeModal = () => {
    navigate("/veiculos/BuscarMotorista");
  };


  const generatePdf = (carregamento) => {
    if (carregamento.STATUS_CARREG === 7) return;

    const pdf = new jsPDF();
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const logoWidth = 50;
    const logoHeight = 18;
    const logoMarginRight = 10;
    const column1StartX = 10;
    const column2StartX = pageWidth / 2;
    const columnWidth = (pageWidth / 2) - 20;

    // Cabeçalho
    pdf.setFont('Helvetica', 'bold');
    pdf.setFontSize(15);
    pdf.text("Armazém JC7", 10, 15);
    pdf.setFont('Helvetica', 'normal');
    pdf.setFontSize(13);
    pdf.text("Rodovia Padre Manoel da Nobrega N°83103", 10, 20);
    pdf.text("(13) 3406-1041", 10, 25);
    pdf.addImage(JC7Logo, 'PNG', pageWidth - logoWidth - logoMarginRight, 10, logoWidth, logoHeight);

    // Título
    pdf.setFontSize(16);
    pdf.text(`BILHETE DE PESAGEM N° ${carregamento.ID_CARREGAMENTO}`, pageWidth / 2, 40, 'center');

    // Linha divisória
    pdf.setDrawColor(0);
    pdf.line(10, 45, pageWidth - 10, 45);

    let startY = 50;

    // Motorista e Veículo
    let motoristaEndY = addSectionWithBoxMotorista(pdf, "Motorista", formatMotoristaDetails(carregamento), column1StartX, startY, columnWidth);
    let veiculoEndY = addSectionWithBoxVeiculo(pdf, "Veículo", formatPlacaDetails(carregamento), column2StartX, startY, columnWidth);

    // Navio e Produto
    startY = Math.max(motoristaEndY, veiculoEndY) + 1;
    let navioEndY = addSectionWithBox(pdf, "Navio", carregamento.NOME_NAVIO, column1StartX, startY, columnWidth);
    let produtoEndY = addSectionWithBox(pdf, "Produto", carregamento.PRODUTO, column2StartX, startY, columnWidth);

    // Cliente e Transportadora
    startY = Math.max(navioEndY, produtoEndY) + 1;
    let clientEndY = addSectionWithBox(pdf, "Cliente", carregamento.NOME_REDUZIDO, column1StartX, startY, columnWidth);
    let transportadoraEndY = addSectionWithBox(pdf, "Transportadora", carregamento.NOME_REDUZIDO_TRANSP, column2StartX, startY, columnWidth);

    // DI e Pedido
    startY = Math.max(clientEndY, transportadoraEndY) + 1;
    let diEndY = addSectionWithBox(pdf, "DI", carregamento.NUMERO_DOC, column1StartX, startY, columnWidth);
    let pedidoEndY = addSectionWithBox(pdf, "Pedido", carregamento.NR_PEDIDO || 'Sem Registro', column2StartX, startY, columnWidth);

    // Peso Bruto e Tara
    startY = Math.max(diEndY, pedidoEndY) + 1;
    let pesoBrutoEndY = addSectionWithBox4(pdf, "Peso Bruto", formatPesoBrutoDetails(carregamento), column1StartX, startY, columnWidth);
    let pesoTaraEndY = addSectionWithBox4(pdf, "Peso Tara", formatPesoTaraDetails(carregamento), column2StartX, startY, columnWidth);

    // Diferença de Peso e Peso Líquido
    startY = Math.max(pesoBrutoEndY, pesoTaraEndY) + 1;
    let diferencaPesoEndY = addSectionWithBox(pdf, "Diferença de Peso", formatDiferencaPesoDetails(carregamento), column1StartX, startY, columnWidth);
    let pesoLiquidoEndY = addSectionWithBox(pdf, "Peso Líquido", formatPesoLiquidoDetails(carregamento), column2StartX, startY, columnWidth);

    // Tipo de Operação e Nota Fiscal
    startY = Math.max(diferencaPesoEndY, pesoLiquidoEndY) + 1;
    addSectionWithBox(pdf, "Tipo de Operação", `${carregamento.TIPO_FLUXO} DE ${carregamento.PRODUTO}`, column1StartX, startY, columnWidth);
    addSectionWithBox(pdf, "Nota Fiscal", formatNotaFiscalDetails(carregamento), column2StartX, startY, columnWidth);

    // Preparando para seção de Assinaturas
    const signatureSectionStartY = pageHeight - 53; // Espaço adequado para a seção de assinaturas
    const signatureSectionHeight = 40; // Altura da seção de assinaturas

    // Assinaturas em duas colunas
    addSignatureSection(pdf, " Assinatura da Empresa", " JC7 Terminais Portuários e Transportes Ltda", column1StartX, signatureSectionStartY, columnWidth, signatureSectionHeight);
    addSignatureSectionMotorista(pdf, " Assinatura do Motorista", carregamento.NOME_MOTORISTA, formatarCPF(carregamento.CPF_MOTORISTA), column2StartX, signatureSectionStartY, columnWidth, signatureSectionHeight);

    // Linha divisória e Footer
    pdf.setDrawColor(0);
    pdf.line(10, 283, pageWidth - 10, 283);
    pdf.setFontSize(9);
    pdf.text("Sistema de Pesagem - CONFIANTEC BALANÇAS\nwww.jc7terminais.com.br", pageWidth / 2, pageHeight - 10, 'center');

    // Salvar o PDF
    pdf.save(`Carregamento-${carregamento.ID_CARREGAMENTO}-${carregamento.TIPO_FLUXO}.pdf`);
  };

  function addSectionWithBox4(pdf, title, content, startX, startY, width, boldContent = false) {
    const cornerRadius = 2; // Raio para os cantos arredondados
    const lineHeight = 6; // Altura da linha para melhor controle
    const sectionHeight = 25; // Altura total da seção para melhor controle

    pdf.setFontSize(11);
    pdf.setFont('Helvetica', 'normal'); // Sempre definir fonte em negrito para o título

    // Posicionar o título à esquerda na caixa
    const titleX = startX + 2; // Pequeno deslocamento da borda esquerda
    pdf.text(`${title}:`, titleX, startY + lineHeight); // Ajustar posição vertical

    // Configurar a fonte para o conteúdo
    pdf.setFont('Helvetica', 'bold'); // Voltar para fonte normal para o conteúdo
    pdf.setFontSize(12); // Definir tamanho da fonte para o conteúdo

    // Centralizar o conteúdo na caixa
    const contentWidth = pdf.getStringUnitWidth(content) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
    const contentX = startX + (width - contentWidth) / 2 + 16; // Calcular a posição x para centralizar o conteúdo

    pdf.text(content, contentX, startY + lineHeight * 2.5); // Ajustar posição vertical

    pdf.setDrawColor(0);
    pdf.setLineWidth(0.2); // Definir a largura da linha para a caixa
    // Desenhar um retângulo arredondado ao redor da seção
    pdf.roundedRect(startX, startY, width, sectionHeight, cornerRadius, cornerRadius, 'S');
    return startY + sectionHeight + lineHeight; // Retornar a posição y após a seção para uso posterior
  }


  function addSectionWithBoxMotorista(pdf, title, content, startX, startY, width, boldContent = false) {
    const cornerRadius = 2; // Raio para os cantos arredondados
    const lineHeight = 6; // Altura da linha para melhor controle
    const sectionHeight = 23; // Altura total da seção para melhor controle

    pdf.setFontSize(11);
    pdf.setFont('Helvetica', 'normal'); // Sempre definir fonte em negrito para o título

    // Posicionar o título à esquerda na caixa
    const titleX = startX + 2; // Pequeno deslocamento da borda esquerda
    pdf.text(`${title}:`, titleX, startY + lineHeight); // Ajustar posição vertical

    // Configurar a fonte para o conteúdo
    pdf.setFont('Helvetica', 'bold'); // Voltar para fonte normal para o conteúdo
    pdf.setFontSize(12); // Definir tamanho da fonte para o conteúdo

    // Centralizar o conteúdo na caixa
    const contentWidth = pdf.getStringUnitWidth(content) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
    const contentX = startX + (width - contentWidth) / 2 + 20; // Calcular a posição x para centralizar o conteúdo

    pdf.text(content, contentX, startY + lineHeight * 2.5); // Ajustar posição vertical

    pdf.setDrawColor(0);
    pdf.setLineWidth(0.2); // Definir a largura da linha para a caixa
    // Desenhar um retângulo arredondado ao redor da seção
    pdf.roundedRect(startX, startY, width, sectionHeight, cornerRadius, cornerRadius, 'S');
    return startY + sectionHeight + lineHeight; // Retornar a posição y após a seção para uso posterior
  }

  function addSectionWithBoxVeiculo(pdf, title, content, startX, startY, width, boldContent = false) {
    const cornerRadius = 2; // Raio para os cantos arredondados
    const lineHeight = 6; // Altura da linha para melhor controle
    const sectionHeight = 23; // Altura total da seção para melhor controle

    pdf.setFontSize(11);
    pdf.setFont('Helvetica', 'normal'); // Sempre definir fonte em negrito para o título

    // Posicionar o título à esquerda na caixa
    const titleX = startX + 2; // Pequeno deslocamento da borda esquerda
    pdf.text(`${title}:`, titleX, startY + lineHeight); // Ajustar posição vertical

    // Configurar a fonte para o conteúdo
    pdf.setFont('Helvetica', 'bold'); // Voltar para fonte normal para o conteúdo
    pdf.setFontSize(12); // Definir tamanho da fonte para o conteúdo

    // Centralizar o conteúdo na caixa
    const contentWidth = pdf.getStringUnitWidth(content) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
    const contentX = startX + (width - contentWidth) / 2 + 37; // Calcular a posição x para centralizar o conteúdo

    pdf.text(content, contentX, startY + lineHeight * 2.5); // Ajustar posição vertical

    pdf.setDrawColor(0);
    pdf.setLineWidth(0.2); // Definir a largura da linha para a caixa
    // Desenhar um retângulo arredondado ao redor da seção
    pdf.roundedRect(startX, startY, width, sectionHeight, cornerRadius, cornerRadius, 'S');
    return startY + sectionHeight + lineHeight; // Retornar a posição y após a seção para uso posterior
  }

  // Funções auxiliares

  function formatMotoristaDetails(carregamento) {
    return `${carregamento.NOME_MOTORISTA}\nCPF: ${formatarCPF(carregamento.CPF_MOTORISTA)}`;
  }

  function formatPlacaDetails(carregamento) {
    return `Cavalo: ${carregamento.PLACA_CAVALO}    Carreta: ${carregamento.PLACA_CARRETA || ' N/A'}\nCarreta 2: ${carregamento.PLACA_CARRETA2 || ' N/A'}    Carreta 3: ${carregamento.PLACA_CARRETA3 || ' N/A'} `;
  }

  function formatPesoBrutoDetails(carregamento) {
    const dataBruto = carregamento.DATA_BRUTO ? new Date(carregamento.DATA_BRUTO).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'America/Sao_Paulo' }) : "Aguardando";
    const pesoBruto = carregamento.PESO_BRUTO ? carregamento.PESO_BRUTO.toLocaleString('pt-BR') : "0";
    return `Data e Hora: ${dataBruto}\nPESO: ${pesoBruto} KG`;
  }

  function formatPesoTaraDetails(carregamento) {
    const dataTara = carregamento.DATA_TARA ? new Date(carregamento.DATA_TARA).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'America/Sao_Paulo' }) : "Aguardando";
    const pesoTara = carregamento.PESO_TARA ? carregamento.PESO_TARA.toLocaleString('pt-BR') : "0";
    return `Data e Hora: ${dataTara}\nPESO: ${pesoTara} KG`;
  }

  function formatDiferencaPesoDetails(carregamento) {
    if (carregamento.PESO_BRUTO && carregamento.PESO_TARA && carregamento.PESO_NOTA_FISCAL) {
      const diferencaDePeso = carregamento.PESO_BRUTO - carregamento.PESO_TARA - carregamento.PESO_NOTA_FISCAL;
      const porcentagemDiferenca = (diferencaDePeso / carregamento.PESO_NOTA_FISCAL) * 100;
      return `${diferencaDePeso.toLocaleString('pt-BR')} KG (${porcentagemDiferenca.toFixed(2).replace('.', ',')}%)`;
    } else {
      return "Sem Peso";
    }
  }

  function formatPesoLiquidoDetails(carregamento) {
    const pesoLiquido = carregamento.PESO_BRUTO && carregamento.PESO_TARA ? carregamento.PESO_BRUTO - carregamento.PESO_TARA : 0;
    return `${pesoLiquido.toLocaleString('pt-BR')} KG`;
  }

  function formatNotaFiscalDetails(carregamento) {
    const pesoNotaFiscal = carregamento.PESO_NOTA_FISCAL ? carregamento.PESO_NOTA_FISCAL.toLocaleString('pt-BR') : "0";
    const numeroNotaFiscal = carregamento.NUMERO_NOTA_FISCAL || "0"; // Se número for nulo, retorna "0"
    return `Número: ${numeroNotaFiscal}, PESO: ${pesoNotaFiscal} KG`;
  }

  function formatarCPF(cpf) {
    return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
  }

  function addSectionWithBox(pdf, title, content, startX, startY, width, boldContent = false) {
    const cornerRadius = 2; // Define the radius for the rounded corners
    const lineHeight = 6; // Define the line height for better control
    const sectionHeight = 18; // Define the section height for better control

    pdf.setFontSize(11);
    pdf.setFont('Helvetica', 'normal'); // Sempre definir fonte em negrito para o título

    // Posicionar o título à esquerda na caixa
    const titleX = startX + 2; // Pequeno deslocamento da borda esquerda
    pdf.text(`${title}:`, titleX, startY + lineHeight); // Ajustar posição vertical

    // Configurar a fonte para o conteúdo
    pdf.setFont('Helvetica', 'bold'); // Voltar para fonte normal para o conteúdo
    pdf.setFontSize(12); // Definir tamanho da fonte para o conteúdo

    // Centralizar o conteúdo na caixa
    const contentWidth = pdf.getStringUnitWidth(content) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
    const contentX = startX + (width - contentWidth) / 2; // Calcular a posição x para centralizar o conteúdo

    pdf.text(content, contentX, startY + lineHeight * 2.5); // Ajustar posição vertical

    pdf.setDrawColor(0);
    pdf.setLineWidth(0.2); // Definir a largura da linha para a caixa
    // Desenhar um retângulo arredondado ao redor da seção
    pdf.roundedRect(startX, startY, width, sectionHeight, cornerRadius, cornerRadius, 'S');
    return startY + sectionHeight + lineHeight; // Retornar a posição y após a seção para uso posterior
  }

  function addSignatureSection(pdf, title, content, startX, startY, width) {
    pdf.setFontSize(10);
    pdf.text(`${title}:`, startX, startY);
    pdf.text(content, startX, startY + 8);
    pdf.setDrawColor(0);
    pdf.setLineWidth(0.2);
    pdf.rect(startX, startY - 5, width, 40, 'S');
  }

  function addSignatureSectionMotorista(pdf, title, name, cpf, startX, startY, width) {
    const lineHeight = 10;
    pdf.setFontSize(10);
    pdf.text(`${title}:`, startX, startY);
    pdf.setFont('Helvetica', 'normal');
    pdf.setFontSize(9);
    pdf.text(name, startX, startY + 5);
    pdf.text(`CPF: ${cpf}`, startX, startY + 9);
    pdf.setDrawColor(0);
    pdf.setLineWidth(0.2);
    pdf.rect(startX, startY - 5, width, 3 * lineHeight + 10, 'S');
  }

  const getDate = () => {
    const date = new Date()
    date.setHours(date.getHours() - 3)
    return (date.toISOString().slice(0, 19).replace('T', ' '))
  }

  const handlePedidoChange = (e) => {
    const selectedPedidoMic = e.target.value;
    setPedidoMic(selectedPedidoMic);

    // Chama getDestino e getTransportadora com o ID do pedido selecionado
    getDestino(selectedPedidoMic);
    getTransportadora(selectedPedidoMic); // Adiciona esta chamada
  };



  const getOperacoes = () => {
    Axios.get('https://portal.jc7transportes.com.br/api/operacao')
      .then((response) => {
        setOperacoesList(response.data)
        console.log(response.data);
        getCargas()
      });
  }

  const getDestino = (idPedido) => {
    Axios.get(`https://portal.jc7transportes.com.br/api/destino/${idPedido}`)
      .then((res) => {
        // A resposta direta contém o nome do destino
        const destinoInfo = res.data;
        setDestino(destinoInfo.NOME_DESTINO); // Atualiza o estado do destino com o nome obtido
      })
      .catch((error) => {
        console.error('Erro ao buscar informações do destino:', error);
        showAlert('Erro ao carregar informações do destino.', 'error');
      });
  };

  const getCargas = (id) => {
    Axios.get(`https://portal.jc7transportes.com.br/api/carga/busca/${id}`,)
      .then(function (res) {
        setDocs(res.data);
        console.log(res.data);
        getPedido(id)
      });
  }

  const getTransp = () => {
    Axios.get(`https://portal.jc7transportes.com.br/api/transportadora`,)
      .then(function (res) {
        setTransportadoras(res.data);
        console.log(res.data);
      });
  }

  const getTipoveiculo = () => {
    Axios.get(`https://portal.jc7transportes.com.br/api/tipoveiculo`,)
      .then(function (res) {
        setTipoveiculos(res.data);
        console.log(res.data);
      });
  }

  const getBigBag = () => {
    Axios.get(`https://portal.jc7transportes.com.br/api/pesagemfinal/bigbag`,)
      .then(function (res) {
        setBigBags(res.data);
        console.log(res.data);
      });
  }

  const getPedido = (id) => {
    Axios.get(`https://portal.jc7transportes.com.br/api/pesageminicial/pedidos/${id}`)
      .then(function (res) {
        setPedidosByDoc(res.data);
        console.log(res.data);
      });
  }

  const getTransportadora = (idPedido) => {
    Axios.get(`https://portal.jc7transportes.com.br/api/transportadoras/pedido/${idPedido}`)
      .then((res) => {
        // Assume que a resposta é um array de transportadoras
        setTransportadoras(res.data); // Atualiza o estado com as transportadoras recebidas
      })
      .catch((error) => {
        console.error('Erro ao buscar transportadoras para o pedido:', error);
        showAlert('Erro ao carregar transportadoras para o pedido.', 'error');
      });
  };


  const getSaldodoc = (id) => {
    Axios.get(`https://portal.jc7transportes.com.br/api/pesageminicial/saldodoc/${id}`)
      .then(function (res) {
        setSaldoTons(res.data);
        // Assuming you want the first element from the response
        if (res.data.length > 0) {
          setSaldoTons(res.data[0].SALDO_TONS);
          setPorcentagem(res.data[0].PORCENTAGEM); // Set the PORCENTAGEM value
        }
      })
      .catch(function (error) {
        console.error('Error fetching SALDO_TONS:', error);
      });
  };

  const { enqueueSnackbar } = useSnackbar();
  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant: variant });
  }

  let { nome } = useParams();
  let { cpf } = useParams();
  let { cnh } = useParams();
  let { id } = useParams();

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div className={style.navbar} onClick={() => navigate("/veiculos/BuscarMotorista")}>
              Motorista
            </div>
            <div className={style.active}>
              1°_Pesagem
            </div>
            <div className={style.radiofluxo}>
              <div className={style.control}>
                <label className={style.input}>
                  <input
                    type="radio"
                    onChange={(e) => [setTipoFluxo(e.target.value), validaFluxo(e.target.value)]}
                    value="ENTRADA"
                    name="tipofluxo"
                    checked={tipofluxo === 'ENTRADA'}
                    aria-label="Entrada"
                  />
                  ENTRADA
                </label>

                <label className={style.input}>
                  <input
                    type="radio"
                    onChange={(e) => [setTipoFluxo(e.target.value), validaFluxo(e.target.value)]}
                    value="SAÍDA"
                    name="tipofluxo"
                    checked={tipofluxo === 'SAÍDA'}
                    aria-label="Saída"
                  />
                  SAÍDA
                </label>
              </div>
            </div>
          </div>

          <div className={style.align}>
            <div className="columns">
              {/* Dados do Motorista */}
              <div className="column is-4">
                <fieldset className={style.fieldset}>
                  <legend className={style.legend}>Dados do Motorista</legend>
                  <div className={style.card}>
                    <div className={style.cabecario}>
                      <p><strong>Motorista:</strong> {nome}</p>
                      <p><strong>CPF:</strong> {cpf}</p>
                      <p><strong>CNH:</strong> {cnh === 'null' ? "Não Registrado" : cnh}</p>
                    </div>
                  </div>
                </fieldset>

                {/* Tipo de Pesagem */}
                <fieldset className={style.fieldset}>
                  <legend className={style.legend}>Tipo de Pesagem</legend>
                  <div className={style.control}>
                    <label className={style.input}>
                      <input
                        type="radio"
                        onChange={(e) => [setTipopesagem(e.target.value), validaTipo(e.target.value)]}
                        value="G"
                        name="tipoPesagem"
                        checked={tipopesagem === 'G'}
                        aria-label="Granel"
                      />
                      GRANEL
                    </label>
                    <label className={style.input}>
                      <input
                        type="radio"
                        onChange={(e) => [setTipopesagem(e.target.value), validaTipo(e.target.value)]}
                        value="B"
                        name="tipoPesagem"
                        checked={tipopesagem === 'B'}
                        aria-label="Big Bag"
                      />
                      BIG BAG
                    </label>
                    <label className={style.input}>
                      <input
                        type="radio"
                        onChange={(e) => [setTipopesagem(e.target.value), validaTipo(e.target.value)]}
                        value="C"
                        name="tipoPesagem"
                        checked={tipopesagem === 'C'}
                        aria-label="Container"
                      />
                      CONTÊINER
                    </label>
                  </div>
                </fieldset>

                {/* Seleção de Navio */}
                <fieldset className={style.fieldset_alt}>
                  <legend className={style.legend}>Escolha o Navio</legend>
                  <select onChange={(e) => [getCargas(e.target.value), setNavio(e.target.value)]}>
                    <option disabled selected>Escolha uma opção</option>
                    {operacoesList?.map((val) => val.STATUS_OPERACAO !== 'FECHADA' && (
                      <option value={val.COD_OPERACAO} key={val.COD_OPERACAO}>{val.NOME_NAVIO}</option>
                    ))}
                  </select>
                </fieldset>

                {/* Peso Cheio ou Tara */}
                <fieldset className={style.fieldset}>
                  <legend className={style.legend}>Peso</legend>
                  {tipofluxo === 'ENTRADA' ? (
                    <Input type="text" text="Peso Cheio (Bruto)" placeholder="Insira o Peso em KG" onChange={(e) => setBruto(e.target.value)} />
                  ) : (
                    <Input type="text" text="Peso Vazio (Tara)" placeholder="Insira o Peso em KG" onChange={(e) => setTara(e.target.value)} />
                  )}
                </fieldset>
              </div>

              {/* Dados do Veículo */}
              <div className="column is-4">
                <fieldset className={style.fieldset_alt}>
                  <legend className={style.legend}>Dados do Veículo</legend>
                  <label>Modelo do AUTO</label>
                  <select
                    onChange={(e) => setTipoveiculo(e.target.value)}
                    value={tipoveiculo}
                  >
                    <option disabled value="">Escolha uma opção</option>
                    {tipoveiculos?.map((val) => (
                      <option key={val.COD_TIPO} value={val.COD_TIPO}>
                        {val.DESC_TIPO_VEICULO}
                      </option>
                    ))}
                  </select>

                  <div className={style.placas}>
                    <div className={style.placaContainer}>
                      <label htmlFor="placaCavalo">Cavalo</label>
                      <input
                        type="text"
                        id="placaCavalo"
                        placeholder="Ex: AAA1234"
                        value={placacavalo}
                        onChange={(e) => setPlacacavalo(e.target.value.toUpperCase())}
                      />
                    </div>

                    <div className={style.placaContainer}>
                      <label htmlFor="placa1">Carreta 1</label>
                      <input
                        type="text"
                        id="placa1"
                        placeholder="Ex: AAA1234"
                        value={placa1}
                        onChange={(e) => setPlaca1(e.target.value.toUpperCase())}
                      />
                    </div>

                    <div className={style.placaContainer}>
                      <label htmlFor="placa2">Doly</label>
                      <input
                        type="text"
                        id="placa2"
                        placeholder="Ex: AAA1234"
                        value={placa2}
                        onChange={(e) => setPlaca2(e.target.value.toUpperCase())}
                      />
                    </div>

                    <div className={style.placaContainer}>
                      <label htmlFor="placa3">Carreta 2</label>
                      <input
                        type="text"
                        id="placa3"
                        placeholder="Ex: AAA1234"
                        value={placa3}
                        onChange={(e) => setPlaca3(e.target.value.toUpperCase())}
                      />
                    </div>
                  </div>
                </fieldset>

                {/* Nota Fiscal */}
                {tipofluxo === 'ENTRADA' && (
                  <fieldset className={style.fieldset}>
                    <legend className={style.legend}>Nota Fiscal</legend>
                    <div className={style.twoColumnGrid}>
                      <Input type="number" text="Número (NF)" placeholder="N° NF" onChange={(e) => setNumeroNotaFiscal(e.target.value)} />
                      <Input type="number" text="Peso (NF)" placeholder="Peso em KG" onChange={(e) => setPesoNotaFiscal(e.target.value)} />
                    </div>
                  </fieldset>

                )}
                {tipopesagem === 'C' && (
                  <fieldset className={style.fieldset}>
                    <legend className={style.legend}>Conteiner</legend>
                    <div className={style.twoColumnGrid}>
                      <label>
                        <input
                          type="radio"
                          name="fluxoConteiner"
                          value="DESOVA"
                          onChange={(e) => setFluxoConteiner(e.target.value)}
                        />
                        Desova
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="fluxoConteiner"
                          value="ESTUFAGEM"
                          onChange={(e) => setFluxoConteiner(e.target.value)}
                        />
                        Estufagem
                      </label>
                    </div>
                  </fieldset>
                )}

              </div>

              {/* Informações da Operação */}
              <div className="column is-4">
                <fieldset className={style.fieldset_alt}>
                  <legend className={style.legend}>Informações da Operação</legend>

                  <div className={style.form_controldoc}>
                    <label>(DI ou BL)</label>
                    <select onChange={(e) => { setDoc(e.target.value); getSaldodoc(e.target.value); getPedido(e.target.value); }}>
                      <option disabled selected>Escolha uma opção</option>
                      {docs?.map((val) => (
                        <option value={val.COD_CARGA} key={val.COD_CARGA}>
                          {val.TIPO} - {val.NUMERO}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* <div className={style.form_controlsaldo} style={{ backgroundColor: porcentagem !== null && porcentagem < 90 ? 'green' : 'red' }}>
                    <div className={style.label}><b>SALDO</b></div>
                    <div className={style.value}>{saldoTons !== null ? saldoTons : ''} Tons</div>
                    <div className={style.value}>{porcentagem !== null ? porcentagem : ''} %</div>
                  </div> */}

                  {tipofluxo === 'ENTRADA' && (tipopesagem === 'B' || tipopesagem === 'G') && (
                    <div className={style.bigbags}>
                      <div className={style.peso_bigbag}>
                        <label>Pedido</label>
                        <select onChange={handlePedidoChange}>
                          <option disabled selected>Escolha opção</option>
                          {pedidosByDoc?.map((val) => (
                            <option value={val.ID_PEDIDO}>{val.NR_PEDIDO}</option>
                          ))}
                        </select>
                      </div>

                      <div className={style.quantidade}>
                        <label>Destino</label>
                        <p>{destino}</p> {/* Exibir o destino */}
                      </div>
                    </div>
                  )}
                </fieldset>
                {/* Dados BigBag ou Contêiner */}
                {(tipopesagem === 'B' || tipopesagem === 'C') && (
                  <fieldset className={style.fieldset}>
                    <legend className={style.legend}>{tipopesagem === 'B' ? 'Big Bag' : 'Dados do Contêiner'}</legend>
                    <div className={style.twoColumnGrid}>
                      {tipopesagem === 'B' && (
                        <>
                          <Input
                            type="number"
                            text="Quantidade Bags"
                            placeholder="Bags"
                            onChange={(e) => setQtdaBigBag(e.target.value)}
                            className={style.inputField}
                          />
                          <div className={style.font}>
                            <label>Peso Bags (KG)</label>
                            <select onChange={(e) => setPesoBigBag(e.target.value)} className={style.selectField}>
                              <option disabled selected>Escolha opção</option>
                              {bigbags?.map((val) => (
                                <option key={val.COD_BIG_BAG} value={val.COD_BIG_BAG}>
                                  {val.PESO_BIG_BAG}
                                </option>
                              ))}
                            </select>
                          </div>
                        </>
                      )}

                      {tipopesagem === 'C' && (
                        <>
                          <Input
                            type="number"
                            text="Quantidade Bags"
                            placeholder="Bags"
                            onChange={(e) => setQtdaBigBag(e.target.value)}
                            className={style.inputField}
                          />
                          <div className={style.font}>
                            <label>Peso Bags (KG)</label>
                            <select onChange={(e) => setPesoBigBag(e.target.value)} className={style.selectField}>
                              <option disabled selected>Escolha opção</option>
                              {bigbags?.map((val) => (
                                <option key={val.COD_BIG_BAG} value={val.COD_BIG_BAG}>
                                  {val.PESO_BIG_BAG}
                                </option>
                              ))}
                            </select>
                          </div>
                          <Input
                            type="text"
                            text="Número do Lacre"
                            placeholder="Nº do Lacre"
                            onChange={(e) => setNumeroLacre(e.target.value)}
                            className={style.inputField}
                          />
                          <Input
                            type="text"
                            text="Núm do Contêiner"
                            placeholder="Nº do Contêiner"
                            onChange={(e) => setNumeroConteiner(e.target.value)}
                            className={style.inputField}
                          />
                        </>
                      )}
                    </div>
                  </fieldset>

                )}



                {/* Transportadora */}
                <fieldset className={style.fieldset_alt}>
                  <legend className={style.legend}>Transportadora</legend>
                  <select onChange={(e) => setTransportadora(parseInt(e.target.value, 10))}>
                    <option disabled selected>Escolha opção</option>
                    {transportadoras.map((val) => (
                      <option value={val.COD_TRANSP} key={val.COD_TRANSP}>
                        {val.NOME_TRANSP}
                      </option>
                    ))}
                  </select>
                </fieldset>



                <div className={style.button}>
                  <SubmitButton text={"Cadastrar"} onClick={validaDados} onKeyPress={(e) => validaTecla(e)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Detalhes do Carregamento"
        className={modalStyles.modal}
        overlayClassName={modalStyles.overlay}
        closeTimeoutMS={200} // Para a animação de fechamento
      >
        {carregamentoDetails ? (
          <div className={modalStyles.content}>
            <h2>Detalhes do Carregamento</h2>
            <div className={modalStyles.details}>
              <p><strong>ID:</strong> {carregamentoDetails.ID_CARREGAMENTO}</p>
              <p><strong>DI:</strong> {carregamentoDetails.NUMERO_DOC}</p>
              <p><strong>Motorista:</strong> {carregamentoDetails.NOME_MOTORISTA}</p>
              <p><strong>Navio:</strong> MV {carregamentoDetails.NOME_NAVIO}</p>
              <p><strong>Produto:</strong> {carregamentoDetails.PRODUTO}</p>
              <p><strong>Cliente:</strong> {carregamentoDetails.NOME_CLIENTE}</p>
              <p><strong>Transportadora:</strong> {carregamentoDetails.NOME_TRANSP}</p>
            </div>
            <div className={modalStyles.modalButtonContainer}>
              <button className={modalStyles.finalizar} onClick={() => generatePdf(carregamentoDetails)}>
                Download PDF
              </button>
              <button className={modalStyles.cancelar} onClick={closeModal}>
                Fechar
              </button>
            </div>
          </div>
        ) : (
          <p>Carregando detalhes...</p>
        )}
      </Modal>



    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}>
      <PesagemFinal />
    </SnackbarProvider >
  );
}

