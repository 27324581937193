import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import { SnackbarProvider } from 'notistack';
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import GraficoPercent from "../../../components/GraficoPercent";
import style from "./PesagemInicial.module.css";
import { Bar } from 'react-chartjs-2';

const PesagemInicial = () => {
  const [list, setList] = useState([]);
  const [totalData, setTotalData] = useState({});
  const [listaDeObjetos, setListaDeObjetos] = useState([]);
  const [moegaView, setMoegaView] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  async function fetchData() {
    try {
      const response = await Axios.get(`https://portal.jc7transportes.com.br/api/grafico/${id}`);
      setList(response.data);

      const totalResponse = await Axios.get(`https://portal.jc7transportes.com.br/api/grafico/total/${id}`);
      setTotalData(totalResponse.data);

      const graficResponse = await Axios.get(`https://portal.jc7transportes.com.br/api/graficdocs/${id}`);
      setListaDeObjetos(graficResponse.data);
    } catch (error) {
      console.error(error);
      // Trate os erros de acordo com suas necessidades
    }
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  const toggleView = () => {
    setMoegaView(!moegaView);
  };

  return (
    <>
      <Navbar operacao />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
          <div className={style.navbar} onClick={() => navigate(`/operacoes`)}>Viagens</div>
          <div className={style.active}>2° Pesagem</div>
          </div>

          <div className={style.buttons}>
            <button onClick={toggleView} className={!moegaView ? `${style.activeButton} ${style.button}` : `${style.button}`}>
              Moega
            </button>
            <button onClick={toggleView} className={moegaView ? `${style.activeButton} ${style.button}` : `${style.button}`}>
              Balança
            </button>
          </div>

          {moegaView ? (  /* BALANÇA */
            <div>
              <div>

                <div className={style.gftitle}>
                  <b>Controle Geral ({totalData.NOME_NAVIO})</b>
                </div>

                <div className={style.gfbox}>
                  <div className={style.gfcolumgeral}>
                    <div className={style.grpercent}>{totalData.PORCENTAGEM_PESO_LIQUIDO_BALANCA} %</div>
                    <div className={style.grbackbar}>
                      <div className={totalData.PORCENTAGEM_PESO_LIQUIDO_BALANCA >= 90 ? `${style.grred}` : `${style.grblue}`}>
                      </div>
                    </div>
                    <div className={style.grdesc}>
                      
                      <div className={style.NOME_REDUZIDO}><b>BALANÇA</b></div>
                      <div className={style.manifestado}>
                        <b>MANIFESTADO:</b> {(totalData.TOTAL_MANIFESTADO / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons
                      </div>

                      <div className={style.linha}></div>
                      <b>Descarregado:</b> {(totalData.TOTAL_PESO_LIQUIDO_BALANCA / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons
                      <br />
                      <b>Saldo:</b> {((totalData.TOTAL_MANIFESTADO - totalData.TOTAL_PESO_LIQUIDO_BALANCA) / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons
                      <br />
                    </div>
                  </div>

                </div>
              </div>

              <div className={style.gftitle}>
                <b>Quantidade descarregada por (DI/BL)</b>
              </div>
              <div className={style.centralizar}>
                <div>
                  {listaDeObjetos.map((graficdocs, index) => (
                    <div key={index} className={style.gfcolum}>

                      <div className={style.grpercent}>{graficdocs.PERC_BALANCA} %</div>
                      <div className={style.grbackbar}>
                        <div className={graficdocs.PERC_BALANCA >= 90 ? `${style.grred}` : `${style.grblue}`}>
                        </div>
                      </div>
                      <div className={style.grdesc}>
                        
                        <div className={style.NOME_REDUZIDO}><b>{graficdocs.NUMERO_DOC}</b></div>
                        <div className={style.manifestado}>{graficdocs.NOME_REDUZIDO}</div>
                        <div className={style.manifestado}><b>{graficdocs.PRODUTO}</b></div>
                        <div className={style.manifestado}>
                          <b>MANIFESTADO:</b> {(graficdocs.MANIFESTADO / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons
                        </div>

                        <div className={style.linha}></div>
                        <b>Descarregado:</b> {(graficdocs.PESO_CARREGADO_BALANCA / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons
                        <br />
                        <b>Saldo:</b> {((graficdocs.SALDO_BALANCA) / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons
                        <br />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (   /* MOEGA */
            <div>
              <div>

                <div className={style.gftitle}>
                  <b>Controle Geral ({totalData.NOME_NAVIO})</b>
                </div>

                <div className={style.gfbox}>
                  <div className={style.gfcolumgeral}>
                    <div className={style.grpercent}>{totalData.PORCENTAGEM_PESO_CARREGADO_MOEGA} %</div>
                    <div className={style.grbackbar}>
                      <div className={totalData.PORCENTAGEM_PESO_CARREGADO_MOEGA >= 90 ? `${style.grred}` : `${style.grblue}`}>
                      </div>
                    </div>
                    <div className={style.grdesc}>
                      
                      <div className={style.NOME_REDUZIDO}><b>MOEGA</b></div>
                      <div className={style.manifestado}>
                        <b>MANIFESTADO:</b> {(totalData.TOTAL_MANIFESTADO / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, })} Tons
                      </div>
                      <div className={style.linha}></div>
                      <b>Descarregado:</b> {(totalData.TOTAL_PESO_CARREGADO_MOEGA / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons
                      <br />
                      <b>Saldo:</b> {((totalData.TOTAL_MANIFESTADO - totalData.TOTAL_PESO_CARREGADO_MOEGA) / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons
                      <br />
                    </div>
                  </div>

                </div>
              </div>


              <div className={style.gftitle}>
                <b>Quantidade descarregada por (DI/BL)</b>
              </div>
              <div className={style.centralizar}>
                <div>
                  {listaDeObjetos.map((graficdocs, index) => (
                    <div key={index} className={style.gfcolum}>

                      <div className={style.grpercent}>{graficdocs.PERC_MOEGA} %</div>
                      <div className={style.grbackbar}>
                        <div className={graficdocs.PERC_MOEGA >= 90 ? `${style.grred}` : `${style.grblue}`}>
                        </div>
                      </div>
                      <div className={style.grdesc}>
                        
                        <div className={style.NOME_REDUZIDO}><b>{graficdocs.NUMERO_DOC}</b></div>
                        <div className={style.manifestado}>{graficdocs.NOME_REDUZIDO}</div>
                        <div className={style.manifestado}><b>{graficdocs.PRODUTO}</b></div>
                        <div className={style.manifestado}>
                          <b>MANIFESTADO:</b> {(graficdocs.MANIFESTADO / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons
                        </div>
                        <div className={style.linha}></div>
                        <b>Descarregado:</b> {(graficdocs.PESO_CARREGADO_MOEGA / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons
                        <br />
                        <b>Saldo:</b> {((graficdocs.SALDO_MOEGA) / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons
                        <br />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}>
      <PesagemInicial />
    </SnackbarProvider>
  );
}

export default IntegrationNotistack;
