import React, { useEffect, useState } from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import Axios from "axios";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import style from "./EmAndamento.module.css";

const EmAndamento = () => {
  const navigate = useNavigate();
  const [operacoesList, setOperacoesList] = useState([]);
  const [selectedNavio, setSelectedNavio] = useState("");
  const [i, setI] = useState(0);
  const [date, setDate] = useState("");

  useEffect(() => {
    getOperacoes();
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant: variant });
  };

  const getOperacoes = () => {
    Axios.get("https://portal.jc7transportes.com.br/api/operacao").then((response) => {
      setOperacoesList(response.data);
    });
  };

  const abrirDash = (id, status) => {
    if (status === "AGUARDANDO DI/BL") {
      showAlert(
        "Documentação deve ser concluída antes de acessar o Dashboard",
        "error"
      );
    }

    if (status === "AGUARDANDO ATRACAÇÃO") {
      showAlert("Um usuário autorizado deve registrar a atracação!", "error");
    }

    if (status === "OPERANDO" || status === "PARALISADO") {
      navigate(`/operacao/${id}`);
    }
  };

  const redirectToViagem = () => {
    if (selectedNavio) {
      navigate(`/operacao/${selectedNavio}`);
    } else {
      showAlert("Por favor, selecione um navio primeiro", "error");
    }
  };

  return (
    <>
      <Navbar relatorios />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div className={style.active}>VIAGENS</div>
          </div>
          
          <div className={style.table}>
            <div className={style.table_outer_container}>
              <div className={style.table_container}>
                {operacoesList
                  .filter(
                    (val) =>
                    val.STATUS_OPERACAO === "OPERANDO" ||
                    val.STATUS_OPERACAO === "PARALISADO" ||
                    val.STATUS_OPERACAO === "AGUARDANDO O INÍCIO DA OPERAÇÃO" ||
                    val.STATUS_OPERACAO === "AGUARDANDO DI/BL"
                  )
                  .map((val, key) => (
                    <div
                      className={style.table_item}
                      key={key}
                      onClick={() => abrirDash(val.COD_OPERACAO, val.STATUS_OPERACAO)}
                    >
                      <div className={style.detalheNavio}>
                        <i className="fas fa-ship"></i> {val.NOME_NAVIO || "-"}
                      </div>
                      <div className={style.detalheNavio}>
                        {val.STATUS_OPERACAO || "-"}
                      </div>
                      <div className={style.detalheNavio}>
                        CLIENTE: {val.NOME_REDUZIDO || "-"}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className={style.dropdownContainer}>
            <select
              className={style.navioDropdown}
              value={selectedNavio}
              onChange={(e) => setSelectedNavio(e.target.value)}
            >
              <option value="">Selecione um Navio</option>
              {operacoesList.map((val, key) => (
                <option key={key} value={val.COD_OPERACAO}>
                  {val.NOME_NAVIO || "-"}
                </option>
              ))}
            </select>
            <button className={style.btnRelatorio} onClick={redirectToViagem}>
              VIAGEM
            </button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={3000}
    >
      <EmAndamento />
    </SnackbarProvider>
  );
}
