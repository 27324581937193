import React from "react";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import Axios from 'axios';
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Select from "../../../components/select"
import SubmitButton from "../../../components/Button";
import moment from 'moment'
import { Navigate, useNavigate } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from 'notistack';
import style from "./CadastroOperacao.module.css";


const CadastroOperacao = () => {

  useEffect(() => {
    getClientes();
  }, [])

  const navigate = useNavigate();

  let { id } = useParams();
  let { nome } = useParams();

  const [clientes, setClientes] = useState();
  const [cliente, setCliente] = useState("");
  const [inicioOperacao, setInicioOperacao] = useState("");
  const usuario = JSON.parse(localStorage.getItem("user_token")).id;

  const getClientes = () => {
    Axios.get('https://portal.jc7transportes.com.br/api/clientes').then((response) => {
      setClientes(response.data);
    });
  }

  const getDate = () => {
    const date = new Date()
    date.setHours(date.getHours() - 3)
    return (date.toISOString().slice(0, 19).replace('T', ' '))
  }

  const { enqueueSnackbar } = useSnackbar();
  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant: variant });
  }

  const addOperacao = () => {
    Axios.post('https://portal.jc7transportes.com.br/api/operacao/criar', {
      cliente: cliente,
      navio: id,
      inicioOperacao: moment(inicioOperacao).format("YYYY-MM-DD"),
      status: 'AGUARDANDO DI/BL',
      usuario: usuario,
      data: getDate()
    })
      .then(function (res) {
        console.log(res);
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Nova operação cadastrada com sucesso!', 'success');
        setTimeout(() => {
          navigate("/navios")
        }, 2000);
      });
  }

  const validaDados = () => {
    if (!cliente | !inicioOperacao ) {
      showAlert('Preencha todos os campos!', 'error')
      return;
    }
    addOperacao()
  }

  return (
    <>
      <Navbar navios />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
        <div className={style.nav}>
            <div className={style.navbar} onClick={() => navigate("/navios")}>
              Voltar
            </div>
            <div className={style.active}>
              Cadastrar Operação
            </div>
          </div>
          <div className={style.navio}>
            <i className="fa fa-ship icon"></i>
            &nbsp;&nbsp;&nbsp;
            {nome}
          </div>
          <div className={style.flex}>
            <div className={style.form_control}>
              <label>Cliente:</label>
              <select onChange={(e) => [setCliente(e.target.value)]}>
                <option disabled selected>Selecione uma opção</option>
                {clientes?.map((val, key) => {
                  return (
                    <option value={val.COD_CLIENTE}>{val.NOME_CLIENTE}</option>
                  )
                })}
              </select>
            </div>
            <Input
              type={"date"}
              text={"Início de Operação"}
              onChange={(e) => [setInicioOperacao(e.target.value)]
              }
            />
          </div>
          <SubmitButton text={"Cadastrar"} onClick={validaDados} />
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}>
      <CadastroOperacao />
    </SnackbarProvider >
  );
}