import React from "react";
import Navbar from "../../components/Navbar";
import Brackground from "../../components/Background";
import Container from "../../components/Container";
import Header from "../../components/Header";
import style from "./Suporte.module.css"

const Suporte = () => {

  return (
    <>
      <Navbar suporte />
      <Header />
      <Brackground />
      <Container>
        
        <div className={style.container}>
        <div className={style.nav}>
            <div className={style.active}>
            Suporte Técnico
            </div>
          </div>
          <div className={style.texto2}>
          Como podemos ajudar?
          </div>
          <div className={style.links_box}>
            <div className={style.quadrado}>
              <i class="fas fa-phone-alt icon2"></i>
              <p className={style.titulo}>Telefone</p>
              <p>+55 (13) 3227-9944</p>
            </div>
            <a href="https://wa.me/13996473530">
              <div className={style.quadrado}>
              <i class="fas fa fa-whatsapp icon2"></i>
              <p className={style.titulo}>WhatsApp</p>
              <p>+55 (13) 99647-3530</p>
            </div>
            </a>
            <a href="mailto:suporte@relianceport.com.br" subject="suporte">
              <div className={style.quadrado}>
              <i class="fas fa-envelope-open-text icon2"></i>
              <p className={style.titulo}>E-mail</p>
              <p>suporte@relianceport.com.br</p>
            </div>
            </a>
            
          </div>
        </div>
      </Container>
    </>
  );
};

export default Suporte;