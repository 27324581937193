import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Navbar from '../../../components/Navbar';
import Brackground from '../../../components/Background';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import SubmitButton from '../../../components/Button';
import Input from '../../../components/Input';
import style from './BuscarPlaca.module.css';
import Modal from './Modal.module.css';
import InputMask from 'react-input-mask';

const BuscarMotorista = () => {
  const navigate = useNavigate();
  const [busca, setBusca] = useState('');
  const [motorista, setMotorista] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  // Estados para o modal
  const [showModal, setShowModal] = useState(false);
  const [carregamentoAberto, setCarregamentoAberto] = useState(null);
  const [historico, setHistorico] = useState([]);

  const getMotorista = () => {
    const placaSemTraco = busca.replace('-', ''); // Remove o traço da placa
  
    Axios.get(`https://portal.jc7transportes.com.br/api/placa/historico/${placaSemTraco}`)
      .then((res) => {
        if (res.data.length > 0) {
          setMotorista(res.data[0]);
          showAlert('Dados do motorista', 'success');
          setHistorico(res.data);  // Atualize o histórico aqui
        } else {
          showAlert('Motorista não cadastrado', 'error');
        }
      })
      .catch((error) => {
        showAlert('Erro ao buscar dados', 'error');
        console.error(error);
      });
  };
  


  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant });
  };

  const validaTecla = (e) => {
    if (e.key === 'Enter') {
      validaDados();
    }
  };

  const validaDados = () => {
    if (!busca) {
      showAlert('Digite uma placa válida!', 'error');
    } else {
      getMotorista();
    }
  };

  const validaPesagem = () => {
    if (!motorista.PLACA_CAVALO) {
      showAlert('Digite uma placa válida!', 'error');
    } else {
      Axios.get(`https://portal.jc7transportes.com.br/api/motorista/valida/${motorista.PLACA_CAVALO}`)
        .then((res) => {
          if (res.data.length > 0) {
            setCarregamentoAberto(res.data[0]);
            setShowModal(true);
          } else {
            navigate(`/veiculos/PesagemFinal/${motorista.NOME_MOTORISTA}/${motorista.PLACA_CAVALO}/${motorista.CNH_MOTORISTA}/${motorista.COD_MOTORISTA}`);
          }
        });
    }
  };

  const confirmarPesagem = () => {
    setShowModal(false);
    navigate(`/veiculos/PesagemFinal/${motorista.NOME_MOTORISTA}/${motorista.PLACA_CAVALO}/${motorista.CNH_MOTORISTA}/${motorista.COD_MOTORISTA}`);
  };

  const cancelarPesagem = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // Implemente a lógica do intervalo aqui, caso necessário
  }, []);

  const formatarCPF = (cpf) => {
    // Adiciona pontos e traço ao CPF
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const formatarPlaca = (placa) => {
    if (!placa) return ''; // Retorna string vazia se a placa for nula ou vazia
    // Adiciona o traço entre as primeiras 3 letras/dígitos e o restante
    return placa.replace(/^([A-Z]{3})([A-Z0-9])([A-Z0-9]{3})$/, '$1-$2$3');
  };

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div className={style.navbar} onClick={() => navigate('/veiculos/buscarmotorista')}>
              Motorista
            </div>
            <div className={style.active}>
              Placa
            </div>
            <div className={style.navbar} onClick={() => navigate('/veiculos')}>
              Cadastrar Motorista
            </div>
          </div>

          <div className="columns">
            <div className="column is-2">
              <div className={style.periodo}>
                <InputMask
                text="Buscar Placa"
                  mask="aaa-9*99"  // Formato para letras e números
                  maskChar=""  // Remove o caractere de preenchimento padrão (underscore)
                  value={busca}
                  onChange={(e) => setBusca(e.target.value.toUpperCase())}  // Converte para maiúsculo
                >
                  {(inputProps) => <Input {...inputProps} placeholder="Digite a placa" />}
                </InputMask>

              </div>
            </div>
            <div className="column">
              <div className={style.submit}>
                <SubmitButton text="Buscar" onClick={validaDados} />
              </div>
            </div>
          </div>

          {/* Tabela de Histórico */}
          {historico.length > 0 && (
            <div className={style.linha}>
              <div className={style.motivacaoContainer}>
                <div className={style.historico}>Histórico do Motorista:</div>
              </div>

              <table className={style.table}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Motorista</th>
                    <th>CPF</th>
                    <th>Navio</th>
                    <th>Cavalo</th>
                    <th>Carreta 1</th>
                    <th>Carreta 2</th>
                    <th>Carreta 3</th>
                    <th>Peso Líquido</th>
                    <th>Transportadora</th>
                    <th>Cadastro</th>
                  </tr>
                </thead>
                <tbody>
                  {historico.map((registro) => (
                    <tr key={registro.ID_CARREGAMENTO}>
                      <td data-label="ID">{registro.ID_CARREGAMENTO}</td>
                      <td data-label="Motorista">{registro.NOME_MOTORISTA}</td>
                      <td data-label="CPF Motorista">{formatarCPF(registro.CPF_MOTORISTA)}</td>
                      <td data-label="Navio">{registro.NOME_NAVIO}</td>
                      <td data-label="Cavalo">{formatarPlaca(registro.PLACA_CAVALO)}</td>
                      <td data-label="Carreta">{formatarPlaca(registro.PLACA_CARRETA)}</td>
                      <td data-label="Carreta">{formatarPlaca(registro.PLACA_CARRETA2)}</td>
                      <td data-label="Carreta">{formatarPlaca(registro.PLACA_CARRETA3)}</td>
                      <td data-label="Peso Líquido">{registro.PESO_LIQUIDO.toLocaleString('pt-BR')} kg</td> {/* Exibindo o Peso Líquido com separador de milhar */}
                      <td data-label="Navio">{registro.NOME_REDUZIDO_TRANSP}</td>
                      <td data-label="Cadastro">
                        {new Date(registro.DATA_CADASTRO).toLocaleDateString('pt-BR', {
                          day: '2-digit',
                          month: '2-digit',
                        })}{' '}
                        -{' '}
                        {new Date(registro.DATA_CADASTRO).toLocaleTimeString('pt-BR', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Container>

    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <BuscarMotorista />
    </SnackbarProvider>
  );
}
