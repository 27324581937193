import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import InputMask from "react-input-mask";
import useAuth from "../../hooks/useAuth";
import logo from "../../img/Logo-JC7.png";
import style from "./Login.module.css";

const NewLogin = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [senha, setSenha] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [razaoSocial, setRazaoSocial] = useState("");
  const [cnpj, setCnpj] = useState("");

  // Validação básica do CNPJ
  const validateCNPJ = (cnpj) => {
    const cleanedCNPJ = cnpj.replace(/[^\d]/g, "");
    if (cleanedCNPJ.length !== 14) return false;
    // Validação simplificada (pode usar bibliotecas específicas)
    return true;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (!id || !senha) {
      setError("Por favor, preencha todos os campos.");
      return;
    }

    login(id.toLowerCase(), senha).then((res) => {
      if (res) {
        setError(res);
        return;
      }
      navigate("/dashboard");
    });
  };

  const handleCadastro = (e) => {
    e.preventDefault();

    if (!validateCNPJ(cnpj)) {
      setError("CNPJ inválido. Por favor, verifique e tente novamente.");
      return;
    }

    const data = {
      razaoSocial,
      cnpj,
    };

    fetch("https://portal.jc7transportes.com.br/api/cadastro/transportadora/agendamento", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          alert("Cadastro realizado com sucesso!");
          window.location.reload(); // Atualiza a página
        } else {
          setError("Erro ao realizar o cadastro. Tente novamente.");
        }
        setIsModalOpen(false);
      })
      .catch((error) => {
        console.error("Erro ao enviar os dados:", error);
        setError("Erro ao conectar-se com o servidor.");
      });
  };

  return (
    <div className={style.container}>
      <div className={style.loginBox}>
        <img src={logo} alt="Logo" className={style.logo} />
        <form onSubmit={handleLogin} className={style.form}>
          <div className={style.inputGroup}>
            <label htmlFor="user">Usuário</label>
            <input
              id="user"
              type="text"
              value={id}
              onChange={(e) => {
                setId(e.target.value);
                setError("");
              }}
              required
            />
          </div>
          <div className={style.inputGroup}>
            <label htmlFor="pass">Senha</label>
            <input
              id="pass"
              type={showPassword ? "text" : "password"}
              value={senha}
              onChange={(e) => {
                setSenha(e.target.value);
                setError("");
              }}
              required
            />
            <span
              className={style.togglePassword}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "Ocultar" : "Mostrar"}
            </span>
          </div>
          {error && <div className={style.errorMsg}>{error}</div>}
          <button type="submit" className={style.loginButton}>
            Entrar
          </button>
        </form>
        <div className={style.firstAccess}>
          <span>Seu primeiro acesso?</span>
          <button
            onClick={() => setIsModalOpen(true)}
            className={style.cadastroButton}
          >
            Cadastre-se
          </button>
        </div>
      </div>

      {/* Modal para Cadastro */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className={style.modal}
        overlayClassName={style.overlay}
      >
        <h2>Cadastro</h2>
        <form onSubmit={handleCadastro} className={style.form}>
          <div className={style.inputGroup}>
            <label htmlFor="razaoSocial">Razão Social</label>
            <input
              id="razaoSocial"
              type="text"
              value={razaoSocial}
              onChange={(e) => setRazaoSocial(e.target.value.toUpperCase())}
              required
            />
          </div>
          <div className={style.inputGroup}>
            <label htmlFor="cnpj">CNPJ</label>
            <InputMask
              id="cnpj"
              mask="99.999.999/9999-99"
              value={cnpj}
              onChange={(e) => setCnpj(e.target.value)}
              required
            />
          </div>
          <button type="submit" className={style.loginButton}>
            Enviar
          </button>
          <button
            type="button"
            onClick={() => setIsModalOpen(false)}
            className={style.cancelButton}
          >
            Cancelar
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default NewLogin;
