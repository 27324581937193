import { Navigate, useNavigate, useParams } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useEffect, useState, } from 'react';
import Axios from "axios";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Navbar from "../../../components/Navbar";
import Pesagem from '@mui/material/Dialog';
import React from "react";
import modal from "./Modal.module.css";
import moment from 'moment';
import style from "./OperacaoSaida.module.css";


const OperacaoSaida = () => {

  useEffect(() => {
    DadosDashboard();
    getVeiculos();
    getQtDescarregado();
    getTotalSaldo();
    getCargas();
    getTipoveiculo();
    getTransportadora();
    getDocdibl();
    getDocs();
    getPedido();
  }, [])



  useEffect(() => {
    const interval_1 = setInterval(() => {
      DadosDashboard();
      getVeiculos();
      getQtDescarregado();
      getTotalSaldo();
    }, 1500)

    return () => clearInterval(interval_1); //This is important

  }, [])

  const navigate = useNavigate();
  let { id } = useParams();

  const [busca, setBusca] = useState("");

  const [dadosDash, setDadosDash] = useState([]);
  const [veiculos, setVeiculos] = useState([]);
  const [descarregado, setDescarregado] = useState([]);
  const [saldo, setSaldo] = useState([]);
  const [docs, setDocs] = useState([]);
  const usuario = JSON.parse(localStorage.getItem("user_token")).id;
  const [i, setI] = useState({});
  const [pesobruto, setPesoBruto] = useState("");
  const [data, setData] = useState();

  const [mostaInput1, setMostaInput1] = useState(false);
  const [mostaInput2, setMostaInput2] = useState(false);
  const [mostaInput3, setMostaInput3] = useState(false);
  const [mostaInput4, setMostaInput4] = useState(false);
  const [mostaInput5, setMostaInput5] = useState(false);
  const [mostaInput6, setMostaInput6] = useState(false);
  const [mostaInput7, setMostaInput7] = useState(false);
  const [mostaInput8, setMostaInput8] = useState(false);
  const [mostaInput9, setMostaInput9] = useState(false);
  const [mostaInput10, setMostaInput10] = useState(false);
  const [viewMode, setViewMode] = useState('MOEGA');

  const [pedidos, setPedidos] = useState([]);
  const [pedido, setPedido] = useState('')
  const [documento, setDocumento] = useState([])
  const [placaCavalo, setPlacaCavalo] = useState('')
  const [placa1, setPlaca1] = useState('')
  const [placa2, setPlaca2] = useState('')
  const [placa3, setPlaca3] = useState('')
  const [tara, setTara] = useState('')
  const [dataTara, setDataTara] = useState('')
  const [tipoveiculos, setTipoveiculos] = useState([])
  const [transportadora, setTransportadora] = useState([])
  const [transportadoras, setTransportadoras] = useState([])
  const [tipoveiculo, setTipoveiculo] = useState([])
  const [docdibl, setDocdibl] = useState([])
  const [docsdibl, setDocsdibl] = useState([])
  const [pesoLiquido, setPesoLiquido] = useState(0);


  //modal de 2 pesagem
  const [openA, setOpenA] = useState(false);
  const AbrirPesagem = () => {
    setOpenA(true);
  };
  const FecharPesagem = () => {
    setOpenA(false);
    setData("");
    setPesoBruto("");
  };

  const [openC, setOpenC] = useState(false);
  const AbrirConfirm = () => {
    setOpenC(true);
  };
  const FecharConfirm = () => {
    setOpenC(false);
  };

  const [MostaInput, setMostaInput] = useState(false);

  const divClick = () => {
    setMostaInput(true);
  };

  // LUCAS MEXEU (INICIAL)
  const DadosDashboard = () => {
    Axios.get(`https://portal.jc7transportes.com.br/api/operacao/segundapesagem/${id}`,)
      .then(function (res) {
        setDadosDash(res.data[0])
      })
  }

  const getVeiculos = () => {
    Axios.get(`https://portal.jc7transportes.com.br/api/operacao/veiculos/saida/${id}`,)
      .then(function (res) {
        setVeiculos(res.data)
      })
  }
  // LUCAS MEXEU (FINAL)

  const getTipoveiculo = () => {
    Axios.get(`https://portal.jc7transportes.com.br/api/tipoveiculo`,)
      .then(function (res) {
        setTipoveiculos(res.data);
      });
  }

  const getTransportadora = () => {
    Axios.get(`https://portal.jc7transportes.com.br/api/transportadora`,)
      .then(function (res) {
        setTransportadoras(res.data);
      });
  }

  const getDocdibl = () => {
    const url = window.location.href; // Obtém a URL atual do navegador
    const parts = url.split("/"); // Divide a URL em partes usando a barra como delimitador
    const idOperacao = parts[parts.length - 1]; // Obtém a parte final da URL (o ID da operação)

    Axios.get(`https://portal.jc7transportes.com.br/api/carga/${idOperacao}`)
      .then(function (res) {
        setDocsdibl(res.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  const getCargas = () => {
    Axios.get(`https://portal.jc7transportes.com.br/api/carga/busca/${id}`,)
      .then(function (res) {
        setDocs(res.data);
      });
  }

  const getDate = () => {
    const date = new Date()
    date.setHours(date.getHours() - 3)
    return (date.toISOString().slice(0, 19).replace('T', ' '))
  }

  const [dis, setDis] = useState([])
  const getDocs = () => {
    Axios.get(`https://portal.jc7transportes.com.br/api/carga/busca/${id}`,)
      .then(function (res) {
        setDis(res.data)
      });
  }

  const getPedido = () => {
    Axios.get(`https://portal.jc7transportes.com.br/api/buscar/pedidos/${id}`,)
      .then(function (res) {
        setPedidos(res.data);
      });
  }

  const getVeiculoAtual = () => {
    return veiculos.find(item => item.ID_CARREGAMENTO === i.ID_CARREGAMENTO) || i
  }

  const getQtDescarregado = () => {
    Axios.get(`https://portal.jc7transportes.com.br/api/dashboard/descarregado/${id}`,)
      .then(function (res) {
        setDescarregado(res.data[0].DESCARREGADO)
      })
  }

  const getTotalSaldo = () => {
    Axios.get(`https://portal.jc7transportes.com.br/api/dashboard/saldo/${id}`,)
      .then(function (res) {
        setSaldo(res.data[0].SALDO)
      })
  }

  const [inputs, setInputs] = useState([
    { name: 'Nome do motorista:', id: 1, value: `${i.NOME_MOTORISTA}`, show: false },
    { name: 'Placa do calvalo: ', id: 2, value: `${i.PLACA_CAVALO}`, show: false },
    { name: 'Placa da carreta 1: ', id: 3, value: `${i.PLACA_CARRETA}`, show: false },
    { name: 'Placa da carreta 2:', id: 4, value: `${i.PLACA_CARRETA2}`, show: false },
    { name: 'Placa da carreta 3:', id: 5, value: `${i.PLACA_CARRETA3}`, show: false },
    { name: '1º peso (Tara):', id: 6, value: `${i.PESO_TARA}`, show: false },
    { name: 'Pedido MIC:', id: 7, value: `${i.PEDIDO_MIC}`, show: false },
  ]);

  const handleDivClick = (id) => {
    const newInputs = [...inputs];
    const index = newInputs.findIndex((input) => input.id === id);
    newInputs[index].show = !newInputs[index].show;
    setInputs(newInputs);
  };

  const divClick2 = () => {
    setMostaInput2(true);
  };
  const divClick3 = () => {
    setMostaInput3(true);
  };
  const divClick4 = () => {
    setMostaInput4(true);
  };
  const divClick5 = () => {
    setMostaInput5(true);
  };
  const divClick6 = () => {
    setMostaInput6(true);
  };
  const divClick7 = () => {
    setMostaInput7(true);
  };
  const divClick8 = () => {
    setMostaInput8(true);
  };
  const divClick9 = () => {
    setMostaInput9(true);
  };
  const divClick10 = () => {
    setMostaInput10(true);
  };



  const toggleView = (mode) => {
    setViewMode(mode);
  };

  const copyToClipboardFallback = text => {
    if (!text) return;

    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        showAlert('Texto copiado com sucesso!', 'success');
      } else {
        showAlert('Falha ao copiar texto.', 'error');
      }
    } catch (err) {
      console.error('Erro ao copiar texto: ', err);
      showAlert('Erro ao copiar texto.', 'error');
    }
    document.body.removeChild(textarea);
  };

  const validaplaca3 = async () => {
    await Axios.put('https://portal.jc7transportes.com.br/api/alterar/carreta3',
      {
        id: i.ID_CARREGAMENTO,
        placa: placa3,
      }).then(function (res) {
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Placa 3 alterada com sucesso!', 'success');
        setMostaInput5(false)
        setOpenA(false);
      });
  }

  const validaVeiculo = async () => {
    await Axios.put('https://portal.jc7transportes.com.br/api/veiculo/atualiza',
      {
        tipoveiculo: tipoveiculo,
        id: i.ID_CARREGAMENTO
      }).then(function (res) {
        res.data.sqlMessage ?

          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Veiculo alterado com sucesso!', 'success');

        setMostaInput7(false)
        setOpenA(false);
      });
  }

  const validaTransportadora = async () => {
    await Axios.put('https://portal.jc7transportes.com.br/api/transportadora/atualiza',
      {
        transportadora: transportadora,
        id: i.ID_CARREGAMENTO
      }).then(function (res) {
        res.data.sqlMessage ?

          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Transportadora alterada com sucesso!', 'success');

        setMostaInput9(false)
        setOpenA(false);
      });
  }

  const validaDocdibl = async () => {
    await Axios.put('https://portal.jc7transportes.com.br/api/docsdibl/atualiza',
      {
        docdibl: docdibl,
        id: i.ID_CARREGAMENTO
      }).then(function (res) {
        res.data.sqlMessage ?

          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Carga alterada com sucesso!', 'success');

        setMostaInput10(false)
        setOpenA(false);
      });
  }

  const validaplaca2 = async () => {
    await Axios.put('https://portal.jc7transportes.com.br/api/alterar/carreta2',
      {
        id: i.ID_CARREGAMENTO,
        placa: placa2,
      }).then(function (res) {
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Placa 2 alterada com sucesso!', 'success');
        setMostaInput4(false)
        setOpenA(false);
      });
  }

  const validaPlaca1 = () => {
    if (!placa1) {
      showAlert('Placa 1 deve conter 7 dígitos!', 'error');
      return;
    }

    atualizaPlaca1()
  }

  const atualizaPlaca1 = async () => {
    await Axios.put('https://portal.jc7transportes.com.br/api/alterar/carreta1',
      {
        id: i.ID_CARREGAMENTO,
        placa: placa1,
      }).then(function (res) {
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Placa 1 alterada com sucesso!', 'success');
        setMostaInput3(false)
        setOpenA(false);
      });
  }

  const validaDoc = async () => {

    await Axios.put('https://portal.jc7transportes.com.br/api/documentos/atualiza',
      {
        documento: parseInt(documento),
        id: i.ID_CARREGAMENTO,
      }).then(function (res) {
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Pedido alterado com sucesso!', 'success');
        setMostaInput8(false)
        setOpenA(false);
      });
  }

  // LUCAS MEXEU (INICIAL)
  const validaCavalo = () => {
    if (!placaCavalo) {
      showAlert('Placa Cavalo deve conter 7 dígitos!', 'error');
      return;
    }
    atualizaCavalo()
  }

  const atualizaCavalo = async () => {
    await Axios.put('https://portal.jc7transportes.com.br/api/alterar/cavalo',
      {
        id: i.ID_CARREGAMENTO,
        placa: placaCavalo,
      }).then(function (res) {
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Placa CAVALO alterada com sucesso!', 'success');
        setMostaInput2(false)
        setOpenA(false);
      });
  }


  const validaTara = () => {
    if (!tara) {
      showAlert('Para atualizar a bruto é necessário ter o peso!', 'error');
      return;
    }
    if (!dataTara) {
      showAlert('Para atualizar a bruto é necessário ter a data!', 'error');
      return;
    }
    atualizaTara()
  }


  const atualizaTara = async () => {
    await Axios.put('https://portal.jc7transportes.com.br/api/alterar/tara',
      {
        tara: tara,
        data: dataTara,
        id: i.ID_CARREGAMENTO,
        usuario: usuario
      }).then(function (res) {
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Tara alterada com sucesso!', 'success');
        setMostaInput6(false)
        setOpenA(false);
      });
  }
  // LUCAS MEXEU (FINAL)

  const validaDados2 = () => {
    if (i.NOME_TRANSPORTADORA == "NÃO INFORMADA") {
      showAlert('Escolha a transportadora da MINUTA', 'error')
      return;
    }
    if (
      (i.PESO_TARA >= 0 && i.PESO_TARA <= 999) || (i.PESO_TARA >= 1001 && i.PESO_TARA <= 7999) || i.PESO_TARA == null) {
      showAlert('Registre o peso TARA corretamente', 'error');
      return;
    }
    if (i.DESC_TIPO_VEICULO == "AGUARDANDO MODELO") {
      showAlert('É obrigatório a escolha do tipo do veículo', 'error')
      return;
    }
    if (pesobruto > 400000000) {
      showAlert('Peso excedido!', 'error')
      return;
    }

    SegundaPesagem();
  }

  const SegundaPesagem = () => {
    Axios.put('https://portal.jc7transportes.com.br/api/segundapesagem/saida', {
      pesobruto: pesobruto,
      data: data,
      usuario: usuario,
      id: i.ID_CARREGAMENTO,
    }).then(function (res) {
      if (res.data.sqlMessage) {
        showAlert(res.data.sqlMessage, 'error');
      } else {
        showAlert('Veículo pesado com sucesso!', 'success');
        // Aguarda um curto período de tempo antes de recarregar a página
        setTimeout(() => {
          window.location.reload();
        }, 2000); // 2000 milissegundos (2 segundos) de atraso antes de recarregar
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
  

  const { enqueueSnackbar } = useSnackbar();
  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant: variant });
  }

  useEffect(() => {
    setPesoLiquido(pesobruto - i.PESO_TARA);
  }, [pesobruto, i.PESO_TARA]);

  const handlePesoBrutoChange = (e) => {
    setPesoBruto(e.target.value);
  };

  return (
    <>
      <Navbar operacao />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          
          <div className={style.nav}>
            <div className={style.nav}>
              <div className={style.navbar} onClick={() => navigate(`/operacoes`)}>
                Operações
              </div>
              <div className={style.active} >
                2°_Pesagem
              </div>
            </div>

            <div className={style.navioNome} style={{ marginLeft: 'auto' }}>
              <span className={style.navbar} onClick={() => navigate(`/operacao/${id}`)}>
                ENTRADA
              </span>
              <span className={style.active}>
                SAÍDA
              </span>
            </div>
          </div>
        
          {dadosDash.SEQ_PERIODO_OP ?
            <div>
              <div className={style.notform}></div>
            </div>
            :
            <div>
              <div className={style.flex}>
                <div className={style.flex}>
                  <div className={style.navio}><i className="fa fa-ship icon"></i>&nbsp;&nbsp;&nbsp;{dadosDash.NOME_NAVIO || "--"}</div>
                </div>
                <div>
                </div>
                <div className={style.status}>
                  <div className={`${style[dadosDash.STATUS_OPERACAO]}`}>
                    <i className="fa fa-truck"></i>&nbsp;&nbsp;{dadosDash.STATUS_OPERACAO || "--"}
                  </div>
                </div>
              </div>



              <div className={style.flex}>
                <div className={style.tara}>
                  <div className={style.taratitulo}>
                    1º Pesagem (TARA)
                    <div>
                      <input type="text" onChange={(e) => { setBusca(e.target.value) }} />
                      <i className="fa fa-search"></i>
                    </div>
                  </div>
                  <div className={style.sumario}>
                    <div>NOME</div>
                    <div>CAVALO</div>
                    <div>DATA | HORA </div>
                  </div>
                  <div className={style.lista}>
                    {veiculos.filter((val) => {
                      let nome = val.NOME_MOTORISTA.trim().split(' ')[0]
                      let horario = moment(val.DATA_BRUTO).format(' DD/MM HH:mm')

                      val.COR = 'item_status_' + (val.STATUS_NOTA_MIC == 1 ? 'def' : val.STATUS_NOTA_MIC)

                      if (busca == "") {
                        return val
                      } else if (nome.toLowerCase().includes(busca.toLowerCase()) || val.PLACA_CAVALO.toLowerCase().includes(busca.toLowerCase()) || horario.toLowerCase().includes(busca.toLowerCase())) {
                        return val
                      }
                    }).map((val, key) => {
                      return (
                        <div className={style.item + ' ' + style[val.COR]} onClick={() => [AbrirPesagem(), setI(val)]}>
                          <div className={style.item_cell}>{val.NOME_MOTORISTA.trim().split(' ')[0] || "-"}</div>
                          <div className={style.item_cell}>{val.PLACA_CAVALO || "-"}</div>
                          <div className={style.item_cell}>{moment(val.DATA_BRUTO).format('DD/MM | HH:mm') || "-"}</div>
                        </div>
                      )
                    })

                    }
                  </div>
                </div>

              </div>
              .
              <div className={style.flexContainerTotal}>
                <div className={style.flexContainer}>

                  {viewMode === 'MOEGA' && (
                    <>
                      <div className={style.dataBox}>
                        <span>DESCARREGADO</span>
                        <span>
                          {descarregado
                            ? (descarregado / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })
                            : "0"
                          } TONS
                        </span>
                      </div>

                      <div className={style.dataBox}>
                        <span>SALDO</span>
                        <span>
                          {saldo
                            ? (saldo / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })
                            : "--"
                          } TONS
                        </span>
                      </div>


                    </>
                  )}

                  <div className={style.dataBox}>
                    <span>MANIFESTADO</span>
                    <span>
                      {dadosDash.MANIFESTADO
                        ? (dadosDash.MANIFESTADO / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })
                        : "--"
                      } TONS
                    </span>
                  </div>

                </div>
              </div>

            </div>}
        </div>
      </Container>
      <Pesagem open={openA} onClose={FecharPesagem} fullWidth>
        <div className={modal.modal}>
          <div className={modal.nav}>
            <div onClick={FecharPesagem}>Voltar</div>
            <div className={modal.active}>2º Pesagem </div>
          </div>
          <div className={modal.flex}>
            
            <div className={modal.motorista}>
              <div className={modal.motoristaid}>
                <div className={style.line}>
                  <b>ID de Carregamento: </b>
                  <span onClick={() => copyToClipboardFallback(i.ID_CARREGAMENTO)} style={{ cursor: 'pointer' }}>
                    {i.ID_CARREGAMENTO}
                  </span>
                </div>


                <div className={style.line}>
                  <b>Motorista: </b>{i.NOME_MOTORISTA}
                </div>
              </div>

              <div className={modal.motoristaid}>
                <div className={style.line}>
                  <b>Cavalo: </b>{mostaInput2 &&
                    < >
                      <input onChange={(e) => { setPlacaCavalo(e.target.value.toUpperCase()) }} placeholder="Placa do cavalo" className={style.inputline} type="text" />
                      <button onClick={validaCavalo} className={style.buttontline} type="submit"><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput2(false)}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </> || i.PLACA_CAVALO}
                  <span><i onClick={divClick2} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>

                  <b className={style.title}>1° Carreta: </b>{mostaInput3 &&
                    <>
                      <input onChange={(e) => { setPlaca1(e.target.value.toUpperCase()) }} placeholder="Placa 1" className={style.inputline} type="text" />
                      <button onClick={validaPlaca1} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput3(false)}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </> || i.PLACA_CARRETA}<span><i onClick={divClick3} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                </div>

                <div className={style.line}>
                  <b>2° Carreta: </b>{mostaInput4 &&
                    <>
                      <input onChange={(e) => { setPlaca2(e.target.value.toUpperCase()) }} placeholder="Placa 2" className={style.inputline} type="text" />
                      <button onClick={validaplaca2} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput4(false)}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </> || i.PLACA_CARRETA2 || "Não registrado"}<span><i onClick={divClick4} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                
                    <b>3° Carreta: </b>{mostaInput5 &&
                    <>
                      <input placeholder="Placa 3" className={style.inputline} type="text" onChange={(e) => { setPlaca3(e.target.value.toUpperCase()) }} />
                      <button onClick={validaplaca3} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput5(false)}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </> || i.PLACA_CARRETA3 || "Não registrado"}<span><i onClick={divClick5} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                </div>

              </div>
              <div className={modal.motoristaid}>
                <div className={style.line}>
                  <b>AUTO: </b>{mostaInput7 &&
                    <>
                      <select className={style.inputline} onChange={(e) => { setTipoveiculo(e.target.value) }}>
                        <option disabled selected>Selecione uma opção</option>
                        {tipoveiculos?.map((val) => {
                          return (
                            <option value={val.COD_TIPO}>{val.DESC_TIPO_VEICULO}</option>
                          )
                        })}
                      </select>

                      <button onClick={validaVeiculo} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput7(false)}><i class="fa fa-times" aria-hidden="true"></i></button>

                    </> || i.DESC_TIPO_VEICULO || "Não registrado"}<span><i onClick={divClick7} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>

                </div>

                <div className={style.line}>
                  <b>1º Pesagem (TARA): </b>{mostaInput6 &&
                    <><div>
                      <input onChange={(e) => { setTara(e.target.value) }} placeholder="Peso da TARA" className={style.inputline} type="text" />
                      <input onChange={(e) => { setDataTara(e.target.value) }} placeholder="Peso da TARA" className={style.inputlinedate} type="datetime-local" /></div>
                      <button onClick={validaTara} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>

                      <button className={style.buttontlinecancel} onClick={() => setMostaInput6(false)}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </> || `${i.PESO_TARA} KG`} <span><i onClick={divClick6} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                </div>

                <div className={style.line}>
                  <b>Pedido MIC: </b>{mostaInput8 &&
                    <>
                      <select className={style.inputline} onChange={(e) => { setDocumento(e.target.value) }}>
                        <option disabled selected>Selecione uma opção</option>
                        {pedidos?.map((val) => {
                          return (
                            <option value={val.NR_PEDIDO}>{val.NR_PEDIDO}</option>
                          )
                        })}
                      </select>

                      <button onClick={validaDoc} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput8(false)}><i class="fa fa-times" aria-hidden="true"></i></button>

                    </> || i.PEDIDO_MIC || "Não Registrado"}<span><i onClick={divClick8} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>

                </div>


                <div className={style.line}>
                  <b>N° DI/BL: </b>{mostaInput10 &&
                    <>
                      <select className={style.inputline} onChange={(e) => { setDocdibl(e.target.value) }}>
                        <option disabled selected>Selecione uma opção</option>
                        {docsdibl?.map((val) => {
                          return (
                            <option value={val.COD_CARGA}>{val.NUMERO_DOC}</option>
                          )
                        })}
                      </select>

                      <button onClick={validaDocdibl} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput10(false)}><i class="fa fa-times" aria-hidden="true"></i></button>

                    </> || i.NUMERO_DOC}<span><i onClick={divClick10} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                </div>
                <div className={style.line}>
                  <b>Transportadora: </b>{mostaInput9 &&
                    <>
                      <select className={style.inputline} onChange={(e) => { setTransportadora(e.target.value) }}>
                        <option disabled selected>Selecione uma opção</option>
                        {transportadoras?.map((val) => {
                          return (
                            <option value={val.COD_TRANSP}>{val.NOME_REDUZIDO}</option>
                          )
                        })}
                      </select>

                      <button onClick={validaTransportadora} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput9(false)}><i class="fa fa-times" aria-hidden="true"></i></button>

                    </> || i.NOME_TRANSPORTADORA}<span><i onClick={divClick9} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                </div>
              </div>
            </div>
          </div>
          <div className={modal.flex}>
            <div className={modal.inputbox_pesagem}>
              2º Pesagem (BRUTO)
              <input type="number" onChange={(e) => { setPesoBruto(e.target.value) }} value={pesobruto} />
            </div>
            {pesobruto > 0 && (
              <div className={modal.inputbox_pesagem}>
                Peso Líquido
                <input type="number" value={pesoLiquido || 0} disabled />
              </div>
            )}
            <div className={modal.inputbox}>
              Data
              <input type={getVeiculoAtual().STATUS_CARREG == 3 ? "text" : "datetime-local"} onChange={(e) => { setData(e.target.value) }} value={data} />
            </div>
          </div>

          <div className={modal.flex}>
            <div className={style.navio}><i className="fa fa-ship icon"></i>&nbsp;&nbsp;&nbsp;{dadosDash.NOME_NAVIO || "--"}</div>
            <button className={style.finalizar} onClick={validaDados2} disabled={getVeiculoAtual().STATUS_CARREG == 3}>REGISTRAR</button>
          </div>
        </div>
      </Pesagem>

    </>
  );

};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={3500}>
      <OperacaoSaida />
    </SnackbarProvider >
  );
}