import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./Destino.module.css";
import Input from "../../../components/Input";
import SubmitButton from "../../../components/Button";
import Axios from "axios";
import InputMask from "react-input-mask";
import { useNavigate } from 'react-router-dom';

import { SnackbarProvider, useSnackbar } from "notistack";

const Destino = () => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [destino, setDestino] = useState("");
  const [destinoLista, setDestinoLista] = useState([]);

  // Função para mostrar alertas
  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant: variant });
  };

  const cadastrarDestino = async () => {
    if (!destino) {
      showAlert("Preencha todos os campos", "error");
      return;
    }
  
    try {
      const response = await Axios.post(
        "https://portal.jc7transportes.com.br/api/destino/cadastrar",
        {
          destino: destino
        }
      );
  
      showAlert("Destino cadastrado com sucesso!", "success");
      // Aguarde 2 segundos (2000 milissegundos) antes de recarregar a página
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Tempo em milissegundos (2 segundos neste exemplo)
    } catch (error) {
      console.error("Erro ao cadastrar o Destino:", error);
      showAlert("Erro ao cadastrar o Destino", "error");
    }
  };

  useEffect(() => {
    Axios.get("https://portal.jc7transportes.com.br/api/destinos")
      .then((response) => {
        setDestinoLista(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados: ", error);
        showAlert("Erro ao buscar dados", "error");
      });
  }, []);

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
        <div className={style.nav}>
                    <div className={style.navbar} onClick={() => navigate("/administrador")}>
                            Administrador
                        </div>
                        <div className={style.navbar} onClick={() => navigate("/AdministradorPage/Transportadora")}>
                            Transportadora +
                        </div>
                        <div className={style.navbar} onClick={() => navigate("/AdministradorPage/Cliente")}>
                            Cliente +
                        </div>
                        <div className={style.navbar} onClick={() => navigate("/AdministradorPage/BigBag")}>
                            Big Bag +
                        </div>
                        <div className={style.active}>
                            Destino +
                        </div>
                    </div>
          <div className={style.columns}>
            
            <div className={style.quantidade}>
              <label>Destino</label>
              <input
                type="text"
                id="destino"
                onChange={(e) => setDestino(e.target.value.toUpperCase())}
              />
            </div>

            <div className={style.submitButton}>
              <SubmitButton text="Cadastrar" onClick={cadastrarDestino} />
            </div>
          </div>

          <table className={style.table}>
            <thead>
              <tr>
                <th>Código Destino</th>
                <th>Destino</th>
              </tr>
            </thead>
            <tbody>
              {destinoLista.map((destino, index) => (
                <tr key={index}>
                  <td>{destino.COD_DESTINO}</td>
                  <td>{destino.NOME_DESTINO}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <Destino />
    </SnackbarProvider>
  );
}
