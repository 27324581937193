import { BrowserRouter, Route, Routes } from "react-router-dom";
import BuscarMotorista from "../pages/veiculos/BuscarMotorista";
import BuscarPlaca from "../pages/veiculos/BuscarPlaca";
import CadastroCarga from "../pages/cargas/CadastroCarga";
import CadastroMotorista from "../pages/veiculos/CadastroMotorista";
import CadastroNavio from "../pages/navios/CadastroNavio";
import CadastroOperacao from "../pages/navios/CadastroOperacao";
import Cargas from "../pages/cargas/Cargas";
import Dashboard from "../pages/Dashboard";
import Cameras from "../pages/Cameras";
import Containers from "../pages/Containers";
import HistoricoContainers from "../pages/Containers/HistoricoContainers";
import Combustivel from "../pages/Suprimentos/Combustivel";
import EmAndamento from "../pages/operacao/EmAndamento";
import { Fragment } from "react";
import PesagemInicial from "../pages/operacao/PesagemInicial";
import Login from "../pages/Login";
import Navios from "../pages/navios/Navios";
import PesagemFinal from "../pages/veiculos/PesagemFinal";
import Operacao from "../pages/operacao/Operacao";
import OperacaoSaida from "../pages/operacao/OperacaoSaida";
import RelatorioEntrada from "../pages/operacao/RelatorioEntrada"
import RelatorioSaida from "../pages/operacao/RelatorioSaida"
import AnaliseOperacional from "../pages/operacao/AnaliseOperacional"
import RelatorioOperacao from "../pages/RelatorioOperacao"
import Relatorios from "../pages/Relatorios";
import ModeloPropostas from "../pages/Comercial/Propostas";
import Propostas from "../pages/Comercial/ModeloPropostas";
import Administrador from "../pages/AdministradorPage/Administrador";
import Cadastramento from "../pages/AdministradorPage/Cadastramento";
import Destino from "../pages/AdministradorPage/Destino";
import Transportadora from "../pages/AdministradorPage/Transportadora";
import Cliente from "../pages/AdministradorPage/Cliente";
import BigBag from "../pages/AdministradorPage/BigBag";
import Suporte from "../pages/Suporte";
import useAuth from "../hooks/useAuth";

import DashboardAgendamento from "../pages/Agendamento/DashboardAgendamento";
import AgendamentoPesagem from "../pages/Agendamento/AgendamentoPesagem";


//recebe item, no caso Home
const Private = ({ Item }) => {
    const { signed } = useAuth();

    //verifica se esta logado encaminha para o item que passou no parametro, no caso Home
    return signed > 0 ? <Item /> : <Login />;
};

const RoutesApp = () => {
    return (
        <BrowserRouter>
            <Fragment>
                <Routes>
                    <Route exact path="/navios" element={<Private Item={Navios} />} />
                    <Route exact path="/navios/cadastro" element={<Private Item={CadastroNavio} />} />
                    <Route exact path="/operacao/cadastro/:nome/:id" element={<Private Item={CadastroOperacao} />} />
                    <Route exact path="/cargas/cadastro/:nome/:id" element={<Private Item={CadastroCarga} />} />
                    <Route exact path="/cargas" element={<Private Item={Cargas} />} />
                    <Route exact path="/operacao/:id" element={<Private Item={Operacao} />} />
                    <Route exact path="/operacaosaida/:id" element={<Private Item={OperacaoSaida} />} />
                    <Route exact path="/operacoes" element={<Private Item={EmAndamento} />} />
                    <Route exact path="/relatorios/:id" element={<Private Item={RelatorioEntrada} />} />
                    <Route exact path="/relatorios/saida/:id" element={<Private Item={RelatorioSaida} />} />
                    <Route exact path="/relatorios/analiseoperacional/:id" element={<Private Item={AnaliseOperacional} />} />
                    <Route exact path="/operacao/pesageminicial/:id" element={<Private Item={PesagemInicial} />} />
                    <Route exact path="/dashboard/:id" element={<Private Item={Dashboard} />} />
                    <Route exact path="/relatorios" element={<Private Item={Relatorios} />} />

                    <Route exact path="/agendamento/dashboardagendamento" element={<Private Item={DashboardAgendamento} />} />
                    <Route exact path="/agendamento/agendamentopesagem" element={<Private Item={AgendamentoPesagem} />} />

                    <Route exact path="/administrador" element={<Private Item={Administrador} />} />
                    <Route exact path="/administradorpage/cadastramento" element={<Private Item={Cadastramento} />} />
                    <Route exact path="/comercial/propostas" element={<Private Item={Propostas} />} />
                    <Route exact path="/comercial/modelopropostas" element={<Private Item={ModeloPropostas} />} />
                    <Route exact path="/suprimentos/combustivel" element={<Private Item={Combustivel} />} />
                    <Route exact path="/administradorpage/destino" element={<Private Item={Destino} />} />
                    <Route exact path="/administradorpage/transportadora" element={<Private Item={Transportadora} />} />
                    <Route exact path="/administradorpage/cliente" element={<Private Item={Cliente} />} />
                    <Route exact path="/administradorpage/bigbag" element={<Private Item={BigBag} />} />
                    <Route exact path="/suporte" element={<Private Item={Suporte} />} />
                    <Route exact path="/cameras" element={<Private Item={Cameras} />} />
                    <Route exact path="/containers" element={<Private Item={Containers} />} />
                    <Route exact path="/containers/historicocontainers" element={<Private Item={HistoricoContainers} />} />
                    <Route exact path="/veiculos/" element={<Private Item={CadastroMotorista} />} />
                    <Route exact path="/veiculos/pesagemfinal/:nome/:cpf/:cnh/:id" element={<Private Item={PesagemFinal} />} />
                    <Route exact path="/veiculos/buscarmotorista" element={<Private Item={BuscarMotorista} />} />
                    <Route exact path="/veiculos/buscarplaca" element={<Private Item={BuscarPlaca} />} />                                
                    <Route path="/" element={<Private Item={Dashboard} />} />
                    <Route path="*" element={<Private Item={Dashboard} />} />
                </Routes>
            </Fragment>
        </BrowserRouter>
    );
};

export default RoutesApp;