import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./Combustivel.module.css";
import Input from "../../../components/Input";
import SubmitButton from "../../../components/Button";
import Axios from "axios";
import InputMask from "react-input-mask";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FaFileExcel } from 'react-icons/fa'; // Para o ícone
import * as XLSX from 'xlsx'; // Para exportar os dados



import { SnackbarProvider, useSnackbar } from "notistack";

const Combustivel = () => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [fornecedor, setFornecedor] = useState("");
  const [fornecedores, setFornecedores] = useState([]);

  const [cupom, setCupom] = useState("");

  const [data, setData] = useState("");

  const [combustivel, setCombustivel] = useState("");

  const [colaborador, setColaborador] = useState("");

  const [veiculo, setVeiculo] = useState("");
  const [veiculos, setVeiculos] = useState([]);

  const [litro, setLitro] = useState("");

  const [taxa, setTaxa] = useState("");

  const [valor, setValor] = useState("");

  const [equipamento, setEquipamento] = useState("");
  const [equipamentos, setEquipamentos] = useState([]);

  const [operacao, setOperacao] = useState("");
  const [operacoes, setOperacoes] = useState([]);

  const [gerador, setGerador] = useState("");

  const [litrotinha, setLitroTinha] = useState("");

  const [abastecido, setAbastecido] = useState("");

  const [combustivelLista, setCombustivelLista] = useState([]);

  const [editMode, setEditMode] = useState(null);
  const [editedDataPagamento, setEditedDataPagamento] = useState('');
  const [editedObservacao, setEditedObservacao] = useState('');

  const [dataInicioFiltro, setDataInicioFiltro] = useState("");
  const [dataTerminoFiltro, setDataTerminoFiltro] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);

  // Função para mostrar alertas
  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant: variant });
  };

  const cadastrarCombustivel = async () => {
    if (!cupom || cupom.length !== 6) {
      showAlert("O cupom deve conter 6 dígitos", "error");
      return;
    }

    // Verifica se um arquivo foi selecionado
    if (selectedFile) {
      const fileName = selectedFile.name.toLowerCase();
      const regex = /\.(jpg|jpeg)$/; // Regex para verificar se o arquivo é JPG ou JPEG

      // Verifica se o arquivo não é um JPG/JPEG
      if (!regex.test(fileName)) {
        showAlert("Somente arquivos em formato JPG são permitidos", "error");
        return; // Encerra a execução da função para evitar o envio do formulário
      }
    }

    const formData = new FormData();
    formData.append('fornecedor', fornecedor);
    formData.append('cupom', cupom);
    formData.append('data', data);
    formData.append('veiculo', veiculo);
    formData.append('colaborador', colaborador);
    formData.append('tipo_combustivel', combustivel);
    formData.append('litros', litro);
    formData.append('taxa', parseFloat(taxa).toFixed(2));
    formData.append('valor', valor);
    formData.append('usuario_cadastro', JSON.parse(localStorage.getItem("user_token")).id);

    if (equipamento) formData.append('equipamento', equipamento);
    if (operacao) formData.append('operacao', operacao);
    if (gerador) formData.append('equipamento_gerador', gerador);
    if (litrotinha) formData.append('quanto_tinha', litrotinha);
    if (abastecido) formData.append('abastecido', abastecido);
    if (selectedFile) formData.append('anexo', selectedFile); // Assegure que o nome aqui corresponde ao esperado pelo backend

    try {
      const response = await Axios.post(
        "https://portal.jc7transportes.com.br/api/combustivel/cadastrar",
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      showAlert("Combustível cadastrado com sucesso!", "success");
      setTimeout(() => window.location.reload(), 2000);
    } catch (error) {
      console.error("Erro ao cadastrar combustível:", error.response ? error.response.data.message : error.message);
      showAlert("Erro ao cadastrar combustível: " + (error.response ? error.response.data.message : error.message), "error");
    }
  };



  const filtrarPorIntervaloDeData = () => {
    return combustivelLista.filter((combustivel) => {
      // Assume que combustivel.DATA já está no formato 'YYYY-MM-DD' ou o converte adequadamente.
      const dataCombustivel = moment(combustivel.DATA);
      const inicio = dataInicioFiltro ? moment(dataInicioFiltro, 'YYYY-MM-DD') : null;
      const termino = dataTerminoFiltro ? moment(dataTerminoFiltro, 'YYYY-MM-DD').endOf('day') : null; // Inclui todo o dia

      if (inicio && termino) {
        return dataCombustivel.isSameOrAfter(inicio) && dataCombustivel.isSameOrBefore(termino);
      } else if (inicio) {
        return dataCombustivel.isSameOrAfter(inicio);
      } else if (termino) {
        return dataCombustivel.isSameOrBefore(termino);
      }
      return true; // Se nenhuma data for definida, mostra todos os registros
    });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    Axios.get("https://portal.jc7transportes.com.br/api/fornecedores")
      .then((response) => {
        setFornecedores(response.data);
        // Automatically select "Posto Arrastão" after fetching
        const postoArrastao = response.data.find(fornecedor => fornecedor.NOME_FORNECEDOR === "POSTO ARRASTAO");
        if (postoArrastao) {
          setFornecedor(postoArrastao.COD_FORNECEDORES);
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar os fornecedores: ", error);
        showAlert("Erro ao buscar os fornecedores", "error");
      });
  }, []);

  useEffect(() => {
    Axios.get("https://portal.jc7transportes.com.br/api/veiculos")
      .then((response) => {
        setVeiculos(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar os veiculos: ", error);
        showAlert("Erro ao buscar os veiculos", "error");
      });
  }, []);

  useEffect(() => {
    Axios.get("https://portal.jc7transportes.com.br/api/equipamentos")
      .then((response) => {
        setEquipamentos(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar equipamentos: ", error);
        showAlert("Erro ao buscar equipamentos", "error");
      });
  }, []);

  useEffect(() => {
    Axios.get("https://portal.jc7transportes.com.br/api/operacao")
      .then((response) => {
        setOperacoes(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar as operações: ", error);
        showAlert("Erro ao buscar as operações", "error");
      });
  }, []);

  useEffect(() => {
    Axios.get("https://portal.jc7transportes.com.br/api/suprimentos/combustivel")
      .then((response) => {
        setCombustivelLista(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados: ", error);
        showAlert("Erro ao buscar dados", "error");
      });
  }, []);

  const handleDownload = (cupom) => {
    // Update the URL to match your backend's address and endpoint
    const url = `https://portal.jc7transportes.com.br/api/suprimentos/combustivel/download/${cupom}`;

    fetch(url)
      .then((response) => {
        if (response.ok) return response.blob();
        throw new Error('Failed to download file.');
      })
      .then((blob) => {
        // Create a URL for the blob
        const downloadUrl = window.URL.createObjectURL(blob);
        // Create an anchor (`<a>`) element to initiate the download
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${cupom}.jpg`); // This sets the filename for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl); // Clean up by revoking the blob URL
      })
      .catch((error) => {
        console.error('Download error:', error.message);
        // Optionally, show an error message to the user
      });
  };
  
const exportToExcel = () => {
  // Preparação dos dados para exportação com formatação
  const data = combustivelLista.map(combustivel => ({
    CUPOM: combustivel.CUPOM,
    DATA: moment(combustivel.DATA).format('DD/MM/YYYY'),
    PLACA: combustivel.PLACA,
    COLABORADOR: combustivel.COLABORADOR.toUpperCase(), // Exemplo de uso de transformação de dados
    LITROS: `${combustivel.LITROS} L`, // Adicionando unidade de medida
    TAXA: `R$ ${parseFloat(combustivel.TAXA).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`, // Formatação para R$ com vírgula e milhar
    VALOR: `R$ ${parseFloat(combustivel.VALOR).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).slice(3)}`, // Formatação de moeda com vírgula e milhar, removendo o símbolo da moeda para evitar duplicação
    MOEGA: combustivel.EquipamentoDesc || 'N/A', // Uso de 'N/A' para dados não disponíveis
    NAVIO: combustivel.NOME_NAVIO || 'N/A',
    QUANTO_TINHA: combustivel.QUANTO_TINHA ? `${combustivel.QUANTO_TINHA} L` : 'N/A',
    ABASTECIDO: combustivel.ABASTECIDO ? `${combustivel.ABASTECIDO} L` : 'N/A',    
  }));

  const ws = XLSX.utils.json_to_sheet(data, {origin: 'A2'}); // Dados começam na linha 2

  XLSX.utils.sheet_add_aoa(ws, [["Relatório de Combustível"]], {origin: 'A1'});

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Combustivel");

  XLSX.writeFile(wb, "RelatorioCombustivel.xlsx");
};


  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>

          <div className={style.nav}>

            <div className={style.active}>
              Combustível
            </div>

          </div>

          <div className={style.columns}>
            <div className={style.itens}>
              <label>FORNECEDOR</label>
              <select
                value={fornecedor}
                onChange={(e) => { setFornecedor(e.target.value) }}
              >
                <option disabled value="">Escolha uma opção</option>
                {fornecedores
                  .filter((fornecedor) => fornecedor.NOME_FORNECEDOR.includes("TESTE"))
                  .map((fornecedorFiltrado) => (
                    <option
                      key={fornecedorFiltrado.COD_FORNECEDORES}
                      value={fornecedorFiltrado.COD_FORNECEDORES}
                    >
                      {fornecedorFiltrado.NOME_FORNECEDOR}
                    </option>
                  ))}
              </select>
            </div>

            <div className={style.itens}>
              <label>CUPOM</label>
              <input
                type="number"
                onChange={(e) => setCupom(e.target.value.toUpperCase())}
              />
            </div>

            <div className={style.cnpj}>
              <Input type={"datetime-local"} text={"DATA E HORA"}
                onChange={(e) => setData(e.target.value)}
              />
            </div>

            <div className={style.itens}>
              <label>PLACA</label>
              <select onChange={(e) => { setVeiculo(e.target.value) }}>
                <option disabled selected>Escolha uma opção</option>
                {veiculos?.map((veiculo) => {
                  return (
                    <option value={veiculo.COD_VEICULO}>{veiculo.PLACA}</option>
                  )
                })}
              </select>
            </div>

            <div className={style.itens}>
              <label>COLABORADOR</label>
              <input
                type="text"
                onChange={(e) => setColaborador(e.target.value.toUpperCase())}
              />
            </div>

            <div className={style.itens}>
              <label>COMBUSTÍVEL</label>
              <select id="combustivel" name="combustivel" onChange={(e) => setCombustivel(e.target.value)}>
                <option disabled selected>Selecione</option>
                <option value="DIESEL S-500">Diesel (S-500)</option>
                <option value="SIM">Gasolina</option>
                <option value="ÁLCOOL">Álcool</option>
              </select>
            </div>

            <div className={style.itens}>
              <label>LITROS</label>
              <input
                type="number"
                onChange={(e) => setLitro(e.target.value.toUpperCase())}
              />
            </div>

            <div className={style.itens}>
              <label>TAXA</label>
              <input
                type="number"
                onChange={(e) => setTaxa(e.target.value.toUpperCase())}
              />
            </div>

            <div className={style.itens}>
              <label>VALOR</label>
              <input
                type="number"
                onChange={(e) => setValor(e.target.value.toUpperCase())}
              />
            </div>

            <div className={style.itens}>
              <label>ANEXO</label>
              <input
                type="file"
                accept=".pdf, .jpg, .jpeg"
                onChange={handleFileChange}
              />
            </div>

          </div>

          <SubmitButton text="Cadastrar" onClick={cadastrarCombustivel} />

          <div className={style.dataFiltro}>
            <FaFileExcel
              onClick={exportToExcel}
              style={{ fontSize: '1.5em', color: 'red', cursor: 'pointer' }}
            />
            <label htmlFor="dataInicioFiltro">Filtrar Data: </label>
            <input
              type="date"
              id="dataInicioFiltro"
              value={dataInicioFiltro}
              onChange={(e) => setDataInicioFiltro(e.target.value)}
            />
            <label htmlFor="dataTerminoFiltro">- </label>
            <input
              type="date"
              id="dataTerminoFiltro"
              value={dataTerminoFiltro}
              onChange={(e) => setDataTerminoFiltro(e.target.value)}
            />
            <button className={style.limparFiltros} onClick={() => { setDataInicioFiltro(""); setDataTerminoFiltro(""); }}>
              Limpar Filtros
            </button>

          </div>

          <table className={style.table}>
            <thead>
              <tr>
                <th>Cupom</th>
                <th>Data</th>
                <th>Placa</th>
                <th>Colaborador</th>
                <th>Litros</th>
                <th>Taxa</th>
                <th>Desconto</th>
                <th>Valor</th>
                <th>Recibo</th>
              </tr>
            </thead>
            <tbody>
              {filtrarPorIntervaloDeData().map((combustiveis, index) => (
                <tr key={index}>
                  <td>{combustiveis.CUPOM}</td>
                  <td>{moment(combustiveis.DATA).format('DD/MM/YYYY')}</td>
                  <td>{combustiveis.PLACA}</td>
                  <td>{combustiveis.COLABORADOR}</td>
                  <td>{(+combustiveis.LITROS).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} L</td>
                  <td>R$ {parseFloat(combustiveis.TAXA).toFixed(2).replace('.', ',')}</td>
                  <td>{((+combustiveis.LITROS) * 0.35).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                  <td>R$ {combustiveis.VALOR.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                  <td>
                    {combustiveis.CUPOM && (
                      <i
                        onClick={() => handleDownload(combustiveis.CUPOM)}
                        style={{ color: "red" }} // Adjust the color as needed
                        className="fa fa-file-image icon"
                        aria-hidden="true"
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <Combustivel />
    </SnackbarProvider>
  );
}
