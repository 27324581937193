import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import Navbar from "../../../components/Navbar";
import Background from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./Cadastramento.module.css";
import { useNavigate } from 'react-router-dom';
import modal from "./Modal.module.css";
import MaskedInput from '../../../components/InputMask'; // Importe o MaskedInput
import { SnackbarProvider, useSnackbar } from 'notistack';

const Cadastramento = () => {
    const navigate = useNavigate();
    const [selectedTable, setSelectedTable] = useState(''); // Captura a tabela selecionada
    const [formData, setFormData] = useState({}); // Armazena os dados do formulário
    const [erro, setErro] = useState('');
    const [tableData, setTableData] = useState([]); // Armazena os dados da tabela
    const [modalData, setModalData] = useState(null); // Estado para armazenar o usuário selecionado e controlar o modal
    const modalContentRef = useRef(null); // Cria um ref para o conteúdo do modal
    const { enqueueSnackbar } = useSnackbar();

    
    const closeModal = () => {
        setModalData(null); // Para o modal principal
    };


    const fetchNaviosOperacao = () => {
        Axios.get("https://set.konexapp.com.br/api/administrador/navio/operacao")
            .then((response) => {
            })
            .catch((error) => {
                console.error("Erro ao buscar navios em operação:", error);
                setErro("Erro ao buscar navios em operação.");
            });
    };

    // Função para formatar CNPJ
    const formatCNPJ = (cnpj) => {
        return cnpj
            .replace(/\D/g, '') // Remove todos os caracteres não numéricos
            .replace(/^(\d{2})(\d)/, '$1.$2')
            .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
            .replace(/\.(\d{3})(\d)/, '.$1/$2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .substring(0, 18); // Limita o CNPJ a 18 caracteres
    };

    // Função para formatar CPF
    const formatCPF = (cpf) => {
        return cpf
            .replace(/\D/g, '') // Remove todos os caracteres não numéricos
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
            .substring(0, 14); // Limita o CPF a 14 caracteres
    };

    // Função para formatar a data
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year} - ${hours}:${minutes}`;
    };

    // Função para lidar com mudanças nos campos de entrada
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            // Armazena o valor sem máscara para o CPF ou CNPJ no estado formData
            [name]: (name === 'CNPJ_CLIENTE' || name === 'CPF') ? value.replace(/\D/g, '') : value || '',
            // Exibe o valor formatado para o CNPJ ou CPF apenas visualmente
            CNPJ_CLIENTE_masked: name === 'CNPJ_CLIENTE' ? formatCNPJ(value) : prevData.CNPJ_CLIENTE_masked,
            CPF_masked: name === 'CPF' ? formatCPF(value) : prevData.CPF_masked,
        }));
    };

    // Função para lidar com a seleção da tabela
    const handleTableSelect = (e) => {
        setSelectedTable(e.target.value);
        setFormData({});
        
        // Carrega a lista de navios em operação apenas para a tabela 'PORAO', mas não chama fetchPoraoData
        if (e.target.value === 'PORAO') {
            fetchNaviosOperacao();
        }
    };

    
    // Função para enviar os dados do formulário para inserção no banco de dados
    const handleSubmit = () => {
    
        if (selectedTable === 'USUARIO') {
            // Código para enviar dados para a tabela USUARIO
            const formattedCPF = formData.CPF.replace(/\D/g, '');
            const userExists = tableData.some((row) => row.USUARIO === formData.USUARIO);
            const cpfExists = tableData.some((row) => row.CPF.replace(/\D/g, '') === formattedCPF);
    
            if (userExists) {
                showAlert("Já existe um usuário registrado com este nome.", 'error');
                return;
            }
    
            if (cpfExists) {
                showAlert("Já existe um usuário registrado com este CPF.", 'error');
                return;
            }
    
            if ((formData.USUARIO && formData.USUARIO.includes('@')) || (formData.NOME && formData.NOME.includes('@'))) {
                showAlert('Os campos "Usuário" e "Nome" não podem conter o caractere "@".', 'error');
                return;
            }
    
            const dataToSubmit = {
                ...formData,
                CPF: formattedCPF
            };
    
            Axios.post(`https://portal.jc7transportes.com.br/api/administrador/${selectedTable.toLowerCase()}`, dataToSubmit)
                .then(response => {
                    showAlert('Dados inseridos com sucesso!', 'success');
                    fetchTableData();
                })
                .catch(error => {
                    console.error('Erro ao inserir dados', error);
                    showAlert('Erro ao inserir dados. Verifique as informações e tente novamente.', 'error');
                });
        } else if (selectedTable === 'CLIENTE') {
            // Código para enviar dados para a tabela CLIENTE
            const formattedCNPJ = formData.CNPJ_CLIENTE.replace(/\D/g, '');
            const cnpjExists = tableData.some((row) => row.CNPJ_CLIENTE.replace(/\D/g, '') === formattedCNPJ);
    
            if (cnpjExists) {
                showAlert("Já existe um cliente registrado com este CNPJ.", 'error');
                return;
            }
    
            const dataToSubmit = {
                ...formData,
                CNPJ_CLIENTE: formattedCNPJ
            };
    
            Axios.post(`https://portal.jc7transportes.com.br/api/administrador/${selectedTable.toLowerCase()}`, dataToSubmit)
                .then(response => {
                    showAlert('Dados inseridos com sucesso!', 'success');
                    fetchTableData();
                })
                .catch(error => {
                    console.error('Erro ao inserir dados', error);
                    showAlert('Erro ao inserir dados. Verifique as informações e tente novamente.', 'error');
                });
        } else {
            // Código para outras tabelas
            const dataToSubmit = {
                ...formData,
                CPF: formData.CPF && formData.CPF.replace(/\D/g, '')
            };
    
            Axios.post(`https://portal.jc7transportes.com.br/api/administrador/${selectedTable.toLowerCase()}`, dataToSubmit)
                .then(response => {
                    showAlert('Dados inseridos com sucesso!', 'success');
                    fetchTableData();
                })
                .catch(error => {
                    console.error('Erro ao inserir dados', error);
                    showAlert('Erro ao inserir dados. Verifique as informações e tente novamente.', 'error');
                });
        }
    };
    
// Função para buscar os dados da tabela selecionada
const fetchTableData = () => {
    if (selectedTable && selectedTable !== 'PORAO') { // Adiciona verificação para ignorar PORAO
        Axios.get(`https://portal.jc7transportes.com.br/api/administrador/${selectedTable.toLowerCase()}`)
            .then(response => {
                setTableData(response.data); // Armazena os dados da tabela
                setErro('');
            })
            .catch(error => {
                console.error('Erro ao buscar dados da tabela', error);
                setTableData([]); // Limpa os dados caso haja erro
            });
    }
};

// Chame `fetchTableData` no useEffect para buscar os dados inicialmente
useEffect(() => {
    if (selectedTable && selectedTable !== 'PORAO') { // Adiciona verificação para ignorar PORAO
        fetchTableData();
    }
}, [selectedTable]);

    // useEffect para buscar os dados da tabela selecionada ao mudar a seleção
useEffect(() => {
    if (selectedTable && selectedTable !== "PORAO") { // Exclui a execução quando selectedTable for "PORAO"
        // Faz a requisição para buscar todos os dados da tabela selecionada
        Axios.get(`https://portal.jc7transportes.com.br/api/administrador/${selectedTable.toLowerCase()}`)
            .then(response => {
                setTableData(response.data); // Armazena os dados da tabela
                setErro('');
            })
            .catch(error => {
                console.error('Erro ao buscar dados da tabela', error);
                setErro('Erro ao buscar dados da tabela. Tente novamente.');
                setTableData([]); // Limpa os dados caso haja erro
            });
    }
}, [selectedTable]);


    // Adiciona o event listener para o clique fora do modal
    useEffect(() => {
        if (modalData) {
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.removeEventListener("mousedown", handleOutsideClick);
        }
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [modalData]);

    const handleOutsideClick = (e) => {
        // Verifica se o clique foi fora do conteúdo do modal
        if (modalContentRef.current && !modalContentRef.current.contains(e.target)) {
            closeModal(); // Chama a função para fechar o modal
        }
    };

    const handlePermissionChange = (e) => {
        const { value, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            permissions: {
                ...prevData.permissions,
                [value]: checked
            }
        }));
    };

    const handleSavePermissions = () => {
        if (!formData.USUARIO) {
            setErro("Usuário não definido. Verifique os dados e tente novamente.");
            return;
        }

        // Converte as permissões em um array de objetos { permission, enabled }
        const permissionsArray = Object.keys(formData.permissions).map((key) => ({
            permission: key,
            enabled: formData.permissions[key]
        }));

        Axios.post(`https://portal.jc7transportes.com.br/api/administrador/usuario/permissoes`, {
            usuario: formData.USUARIO,
            permissions: permissionsArray
        })
            .then(response => {
                showAlert('Permissões salvas com sucesso!', 'success');
                closeModal();
            })
            .catch(error => {
                console.error("Erro ao salvar permissões:", error);
                setErro("Erro ao salvar permissões. Tente novamente.");
            });
    };

    const showAlert = (txt, variant) => {
        enqueueSnackbar(txt, { variant });
    };

    const openModal = (row) => {
        setModalData(row);
        setFormData({
            ...row,
            EMAILS: row.EMAILS || '',
            PRODUTO: row.PRODUTO || '',
            NCM: row.NCM || '',
            IND_CARGA_IMO: row.IND_CARGA_IMO || '',
        });

        console.log("COD_PRODUTO capturado no openModal:", row.COD_PRODUTO); // Verifica o COD_PRODUTO

        if (selectedTable === 'USUARIO') {
            Axios.get(`https://portal.jc7transportes.com.br/api/administrador/usuario/permissoes/${row.USUARIO}`)
                .then(response => {
                    const permissions = response.data.reduce((acc, perm) => {
                        acc[perm.NAV_BAR] = true;
                        return acc;
                    }, {});
                    setFormData(prevData => ({
                        ...prevData,
                        permissions
                    }));
                })
                .catch(error => {
                    console.error("Erro ao carregar permissões:", error);
                    setErro("Erro ao carregar permissões. Tente novamente.");
                });
        }
    };
    
    return (
        <>
            <Navbar />
            <Header />
            <Background />
            <Container>
                <div className={style.content}>
                <div className={style.nav}>
                                <div className={style.navItem} onClick={() => navigate('/administradorpage/administrador')}>Cadastramento</div>
                                <div className={style.navItemActive}>{'>'} Cadastramento</div>
                            </div>

                    {/* Formulário em 3 colunas */}
                    <div className={style.formContainer}>
                        <select
                            value={selectedTable}
                            onChange={handleTableSelect}
                            className={style.inputField}
                        >
                            <option value="">Selecione a Tabela</option>
                            <option value="USUARIO">USUARIO</option>
                        </select>

                        {selectedTable === 'USUARIO' && (
                            <>
                                <input
                                    type="text"
                                    placeholder="Usuário"
                                    name="USUARIO"
                                    value={formData.USUARIO || ''}
                                    onChange={handleInputChange}
                                    className={style.inputField}
                                />
                                <input
                                    type="text"
                                    placeholder="Nome"
                                    name="NOME"
                                    value={formData.NOME || ''}
                                    onChange={handleInputChange}
                                    className={style.inputField}
                                />
                                <input
                                    type="text"
                                    placeholder="000.000.000-00"
                                    name="CPF"
                                    value={formData.CPF_masked || ''} // Exibe o CPF com máscara visual
                                    onChange={handleInputChange}
                                    className={style.inputField}
                                />

                                <input
                                    type="email"
                                    placeholder="Email"
                                    name="EMAIL"
                                    value={formData.EMAIL || ''}
                                    onChange={handleInputChange}
                                    className={style.inputField}
                                />
                                <input
                                    type="password"
                                    placeholder="Senha"
                                    name="SENHA"
                                    value={formData.SENHA || ''}
                                    onChange={handleInputChange}
                                    className={style.inputField}
                                />
                                <div className={style.statusToggle}>
                                    <button
                                        type="button"
                                        onClick={() => setFormData({ ...formData, ATIVO: formData.ATIVO === 'S' ? 'N' : 'S' })}
                                        className={style.toggleButton}
                                        style={{
                                            backgroundColor: formData.ATIVO === 'S' ? 'green' : 'red',
                                            color: 'white',
                                        }}
                                    >
                                        {formData.ATIVO === 'S' ? 'Ativo' : 'Inativo'}
                                    </button>
                                </div>

                            </>
                        )}

                        {/* Botão para cadastrar os dados inseridos */}
                        <div className={style.submitButtonContainer}>
                            <button onClick={handleSubmit} className={style.submitButton}>Cadastrar</button>
                        </div>
                    </div>

                    {/* Mensagem de erro */}
                    {erro && <p className={style.error}>{erro}</p>}

                    {/* Tabela exibindo os dados da tabela selecionada */}
                    {selectedTable && (
                        <div className={style.tableContainer}>
                            <table className={style.table}>
                                <thead>
                                    <tr>
                                        {selectedTable === 'USUARIO' && (
                                            <>
                                                <th>USUÁRIO</th>
                                                <th>NOME</th>
                                                <th>CPF</th>
                                                <th>EMAIL</th>
                                                <th>SENHA</th>
                                                <th>ATIVO</th>
                                                <th>DATA DE CADASTRO</th>
                                            </>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData.map((row, index) => (
                                        <tr
                                            key={index}
                                            onClick={() => (selectedTable === 'USUARIO' || selectedTable === 'CLIENTE' || selectedTable === 'PRODUTO' || selectedTable === 'PORAO') && openModal(row)} // Abre o modal também para PORAO
                                        >
                                            {Object.keys(row)
                                                .filter(key => !key.startsWith("COD_") && key !== "UN_MEDIDA") // Ignora colunas que começam com "COD_" e a coluna "UN_MEDIDA"
                                                .map((key, idx) => (
                                                    <td key={idx}>
                                                        {key === 'DAT_CADASTRO' ? formatDate(row[key]) :
                                                            key === 'SENHA' ? '*******' : // Exibe asteriscos para a coluna SENHA
                                                                key === 'CPF' ? formatCPF(row[key]) : // Formata CPF com base na função formatCPF
                                                                    key.includes("CNPJ") && row[key] ? formatCNPJ(row[key]) :
                                                                        key === 'IND_CARGA_IMO' ? (row[key] === 'S' ? 'SIM' : row[key] === 'N' ? 'NÃO' : '') :
                                                                            row[key] || ''}
                                                    </td>
                                                ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                    {/* Modal com os dados do usuário e radio buttons */}
                    {modalData && selectedTable === 'USUARIO' && (
                        <div className={modal.modal} onClick={handleOutsideClick}>
                            <div className={modal.modalContent} ref={modalContentRef}>
                                <h3>Detalhes do Usuário</h3>

                                <div className={modal.formGroup}>
                                    <label>Nome:</label>
                                    <input
                                        type="text"
                                        name="NOME"
                                        value={formData.NOME || modalData.NOME}
                                        onChange={handleInputChange}
                                        className={modal.inputField}
                                    />
                                </div>

                                <div className={modal.formGroup}>
                                    <label>CPF:</label>
                                    <input
                                        type="text"
                                        placeholder="000.000.000-00"
                                        name="CPF"
                                        value={formData.CPF || modalData.CPF}
                                        onChange={handleInputChange}
                                        className={modal.inputField}
                                    />
                                </div>

                                <div className={modal.formGroup}>
                                    <label>Email:</label>
                                    <input
                                        type="email"
                                        name="EMAIL"
                                        value={formData.EMAIL || modalData.EMAIL}
                                        onChange={handleInputChange}
                                        className={modal.inputField}
                                    />
                                </div>

                                <h4 className={modal.permissionTitle}>Permissões</h4>
                                <div className={modal.radioButtons}>
                                    {['CÂMERAS', 'CONTEINER', 'NAVIOS', 'DOCS OPERAÇÃO', 'SUPRIMENTOS', 'AUTOS', '2° PESAGEM', 'COMERCIAL', 'RELATÓRIOS', 'ADMINISTRADOR', 'AGENDAMENTO'].map((label, index) => (
                                        <div key={index} className={modal.checkboxOption}>
                                            <input
                                                type="checkbox"
                                                id={`perm_${label}`}
                                                name="permissions"
                                                value={label}
                                                checked={formData.permissions?.[label] || false}
                                                onChange={handlePermissionChange}
                                            />
                                            <label htmlFor={`perm_${label}`}>{label}</label>
                                        </div>
                                    ))}
                                </div>
                                <button className={modal.saveButton} onClick={handleSavePermissions}>Salvar</button>
                            </div>
                        </div>
                    )}

                </div>
            </Container>
        </>
    );
};

// Envolvendo Cadastramento com SnackbarProvider
export default function IntegrationNotistack() {
    return (
        <SnackbarProvider
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            maxSnack={3}
            autoHideDuration={2500}
        >
            <Cadastramento />
        </SnackbarProvider>
    );
}