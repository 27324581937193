import React, { useEffect, useState } from "react";
import Axios from 'axios';
import Navbar from "../../../components/Navbar";
import { SnackbarProvider, useSnackbar } from 'notistack';
import Background from "../../../components/Background";
import Container from "../../../components/Container";
import SubmitButton from "../../../components/Button";
import Header from "../../../components/Header";
import Detalhes from '@mui/material/Dialog';
import Confirm from '@mui/material/Dialog';
import Input from "../../../components/Input";
import { useNavigate } from "react-router-dom";
import style from "./Cargas.module.css";
import modal from "./Modal.module.css";
import confirm from "./Confirm.module.css";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';


const Cargas = () => {
  const navigate = useNavigate();
  const [operacoesList, setOperacoesList] = useState([]);
  const [cargas, setCargas] = useState([]);
  const [pedidos, setPedidos] = useState([]);
  const [i, setI] = useState(0);
  const [ideta, setIdeta] = useState("");
  const [idet, setIdet] = useState("");
  const [activeTab, setActiveTab] = useState(0); // Inicialize com 0
  const [cargasRelacionadas, setCargasRelacionadas] = useState([]);
  const [selectedCarga, setSelectedCarga] = useState('');
  const [selectedPedido, setSelectedPedido] = useState('');
  const [nrPedido, setNrPedido] = useState('');
  const [nrTransportadora, setNrTransportadora] = useState('');
  const [pedidoData, setPedidoData] = useState([]); // Estado para armazenar os dados do pedido
  const [showPedidoData, setShowPedidoData] = useState(false);
  const [selectedNavio, setSelectedNavio] = useState("");
  const [selectedImportador, setSelectedImportador] = useState('');
  const [destinos, setDestinos] = useState([]);
  const [selectedDestino, setSelectedDestino] = useState('');
  const [selectedTipoOperacao, setSelectedTipoOperacao] = useState('');
  const [selectedTransportadoras, setSelectedTransportadoras] = useState([]);
  const [transportadoras, setTransportadoras] = useState([]);
  const [pedidosPorCarga, setPedidosPorCarga] = useState([]);
  const [transportadorasPorPedido, setTransportadorasPorPedido] = useState([]);
  const [showTransportadoraData, setShowTransportadoraData] = useState(false);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    getOperacoes();
    getDestinos();
    getTransportadoras();
  }, []);

  const getOperacoes = () => {
    Axios.get('https://portal.jc7transportes.com.br/api/operacao').then((response) => {
      setOperacoesList(response.data);
    });
  }

  const getDestinos = () => {
    Axios.get('https://portal.jc7transportes.com.br/api/destinos')
      .then((response) => {
        setDestinos(response.data);
      })
      .catch((error) => {
        console.error('Erro ao buscar destinos:', error);
        showAlert('Erro ao carregar destinos.', 'error');
      });
  };

  const getTransportadoras = () => {
    Axios.get('https://portal.jc7transportes.com.br/api/transportadora')
      .then((response) => {
        setTransportadoras(response.data);
      })
      .catch((error) => {
        console.error('Erro ao buscar transportadoras:', error);
        showAlert('Erro ao carregar transportadoras.', 'error');
      });
  };

  const handleCargaChange = (e) => {
    const selectedCargaId = e.target.value;
    setSelectedCarga(selectedCargaId);

    // Encontra o objeto da carga selecionada e atualiza o estado conforme necessário
    const selectedCargaObj = cargas.find(carga => carga.COD_CARGA.toString() === selectedCargaId);
    if (selectedCargaObj) {
      setSelectedImportador(selectedCargaObj.IMPORTADOR);
      loadPedidoData(selectedCargaId); // Chamada existente para carregar dados do pedido
      setShowPedidoData(true);
    } else {
      setShowPedidoData(false);
      setSelectedImportador('');
    }

    // Carrega os pedidos relacionados à carga selecionada
    getPedidosPorCarga(selectedCargaId);
  };

  const getPedidosPorCarga = (idCarga) => {
    Axios.get(`https://portal.jc7transportes.com.br/api/pedido/carga/${idCarga}`)
      .then((response) => {
        setPedidosPorCarga(response.data);
      })
      .catch((error) => {
        console.error('Erro ao buscar pedidos por carga:', error);
        showAlert('Erro ao carregar pedidos por carga.', 'error');
      });
  };


  const getCargas = (id) => {
    Axios.get(`https://portal.jc7transportes.com.br/api/carga/busca/${id}`).then(function (res) {
      console.log(res.data);
      setCargas(res.data);
      setIdeta(id);
    });
  }

  const getPedidos = (id) => {
    Axios.get(`https://portal.jc7transportes.com.br/api/carga/pedido/${id}`).then(function (res) {
      console.log(res.data);
      setPedidos(res.data);
      setIdet(id);
    });
  }

  const [openA, setOpenA] = useState(false);

  const DetalhesOp = () => {
    setOpenA(true);
  };

  const FecharDetalhesOp = () => {
    setOpenA(false);
  };

  const DetalharOp = (index) => {
    setI(operacoesList[index]);
    setPedidoData([]); // Limpar o estado da lista de pedidos
  };


  const [openB, setOpenB] = useState(false);

  const AbrirConfirm = () => {
    FecharDetalhesOp();
    setOpenB(true);
  };

  const FecharConfirm = () => {
    setOpenB(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant: variant });
  };

  const registrarAtracacao = () => {
    Axios.put('https://portal.jc7transportes.com.br/api/operacao/registrar/atracacao', {
      id: i.COD_OPERACAO,
    }).then(function (res) {
      res.data.sqlMessage
        ? showAlert(res.data.sqlMessage, 'error')
        : showAlert('Atracação registrada com sucesso!', 'success');
      FecharConfirm();
      getOperacoes();
    });
  };

  const handleItemClick = () => {
    setActiveTab(1);
  };

  const handlePedidoSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCarga || !nrPedido) {
      showAlert("Preencha todos os campos antes de adicionar um pedido.", 'error');
      return;
    }

    // Faça uma solicitação para adicionar o pedido ao banco de dados
    try {
      const response = await Axios.post('https://portal.jc7transportes.com.br/api/cargas/pedido', {
        codOperacao: i.COD_OPERACAO, // Suponho que você deseje usar o COD_OPERACAO da operação atual
        nrPedido: nrPedido,
        codCarga: selectedCarga,
        codDestino: selectedDestino,
        tipoOperacao: selectedTipoOperacao,
      });

      if (response.status === 200) {
        showAlert('Pedido adicionado com sucesso!', 'success');
        // Limpar campos ou fazer outras ações necessárias após a inserção
        setNrPedido('');
        setSelectedCarga('');
        loadPedidoData(selectedCarga); // Carregar dados do pedido com base no COD_OPERACAO e COD_CARGA
      } else {
        showAlert('Erro ao adicionar o pedido.', 'error');
      }
    } catch (error) {
      showAlert('Erro ao adicionar o pedido.', 'error');
      console.error(error);
    }
  };

  // Função para carregar dados do pedido com base no COD_OPERACAO e COD_CARGA
  const loadPedidoData = async (codCarga) => {
    try {
      const response = await Axios.get(`https://portal.jc7transportes.com.br/api/cargas/pedido/${codCarga}`);
      if (response.status === 200) {
        setPedidoData(response.data);
      } else {
        showAlert('Erro ao carregar os dados do pedido.', 'error');
      }
    } catch (error) {
      showAlert('Erro ao carregar os dados do pedido.', 'error');
      console.error(error);
    }
  };

  const handleNavioChange = (e) => {
    setSelectedNavio(e.target.value);
  };

  const handleDocViagemClick = () => {
    // Log the selectedNavio for debugging
    console.log('Selected Navio:', selectedNavio);

    const selectedOperationIndex = operacoesList.findIndex(op => String(op.COD_OPERACAO) === selectedNavio);

    if (selectedOperationIndex === -1) {
      showAlert("Por favor, selecione um navio válido", "error");
      return;
    }

    const selectedOperation = operacoesList[selectedOperationIndex];
    console.log('Selected Operation:', selectedOperation); // Debugging log

    DetalhesOp();
    DetalharOp(selectedOperationIndex);
    getCargas(selectedOperation.COD_OPERACAO);
    getPedidos(selectedOperation.COD_OPERACAO);
    setActiveTab(1); // Adjust this index based on your tabs setup
  };

  const handleTransportadoraChange = (codTransp) => {
    // Verifica se a transportadora já está selecionada
    const isSelected = selectedTransportadoras.includes(codTransp);
    if (isSelected) {
      // Remove a transportadora das selecionadas
      setSelectedTransportadoras(selectedTransportadoras.filter(item => item !== codTransp));
    } else {
      // Adiciona a transportadora às selecionadas
      setSelectedTransportadoras([...selectedTransportadoras, codTransp]);
    }
  };

  const handleTransportadoraSubmit = async (e) => {
    e.preventDefault();
  
    if (!selectedPedido || selectedTransportadoras.length === 0) {
      showAlert("Selecione pelo menos um pedido e uma transportadora.", 'error');
      return;
    }
  
    const promises = selectedTransportadoras.map(codTransp => {
      return Axios.post('https://portal.jc7transportes.com.br/api/cargas/transcarreg', {
        COD_OPERACAO: i.COD_OPERACAO,
        ID_PEDIDO: selectedPedido,
        COD_CARGA: selectedCarga,
        COD_TRANSP: codTransp,
      });
    });
  
    try {
      // Aguarda todas as requisições serem completadas
      await Promise.all(promises);
  
      // Se todas as requisições forem bem-sucedidas
      showAlert('Transportadoras adicionadas com sucesso!', 'success');
      setSelectedTransportadoras([]); // Limpa as transportadoras selecionadas
      
      // Atualiza os dados das transportadoras na tela
      loadTransportadoraData(selectedPedido);
    } catch (error) {
      showAlert('Erro ao adicionar transportadoras.', 'error');
      console.error(error);
    }
  };
  
  
  const handlePedidoChange = (e) => {
    const selectedPedidoId = e.target.value;
    setSelectedPedido(selectedPedidoId);

    if (selectedPedidoId) {
      getTransportadorasPorPedido(selectedPedidoId);
    } else {
      setTransportadorasPorPedido([]);
      setShowTransportadoraData(false);
    }
  };

  const getTransportadorasPorPedido = async (idPedido) => {
    try {
      const response = await Axios.get(`https://portal.jc7transportes.com.br/api/transportadoras/pedido/${idPedido}`);
      if (response.data && response.data.length > 0) {
        setTransportadorasPorPedido(response.data);
        setShowTransportadoraData(true);
      } else {
        // Resposta bem-sucedida, mas sem dados
        setTransportadorasPorPedido([]);
        setShowTransportadoraData(true); // Ainda queremos mostrar a tabela, mas ela estará vazia
      }
    } catch (error) {
      console.error('Erro ao buscar transportadoras por pedido:', error);
      setShowTransportadoraData(false); // Não mostrar a tabela se ocorrer um erro
      // A mensagem de erro só deve ser exibida se ocorrer um erro real, não se simplesmente não houver dados
    }
  };

  const loadTransportadoraData = async (idPedido) => {
    try {
      console.log(`Carregando dados para o pedido: ${idPedido}`);
      const response = await Axios.get(`https://portal.jc7transportes.com.br/api/cargas/transcarreg/${idPedido}`);
      console.log('Resposta da API:', response.data);
  
      if (response.data && response.data.length > 0) {
        setTransportadorasPorPedido(response.data);
        setShowTransportadoraData(true);
      } else {
        setTransportadorasPorPedido([]);
        setShowTransportadoraData(false);
      }
    } catch (error) {
      console.error('Erro ao carregar dados das transportadoras:', error);
      showAlert('Erro ao carregar os dados das transportadoras.', 'error');
      setShowTransportadoraData(false);
    }
  };
  

  function formatarCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, '');
    cnpj = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    return cnpj;
}

  return (
    <>
      <Navbar cargas />
      <Header />
      <Background />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div className={style.active}>DOCS OPERAÇÃO</div>
          </div>

          <div className={style.table}>
            <div className={style.table_outer_container}>
              <div className={style.table_container}>
                {operacoesList
                  .filter(
                    (val) =>
                      val.STATUS_OPERACAO === "OPERANDO" ||
                      val.STATUS_OPERACAO === "PARALISADO" ||
                      val.STATUS_OPERACAO === "AGUARDANDO O INÍCIO DA OPERAÇÃO" ||
                      val.STATUS_OPERACAO === "AGUARDANDO DI/BL"
                  )
                  .map((val, key) => (
                    <div
                      className={style.table_item}
                      key={key}
                      onClick={() => {
                        DetalhesOp();
                        DetalharOp(key);
                        getCargas(val.COD_OPERACAO);
                        getPedidos(val.COD_OPERACAO);
                        handleItemClick();
                      }}
                    >
                      <div className={style.detalheNavio}>
                        <i className="fas fa-ship"></i> {val.NOME_NAVIO || "-"}
                      </div>
                      <div className={style.detalheNavio}>
                        {val.STATUS_OPERACAO || "-"}
                      </div>
                      <div className={style.detalheNavio}>
                        CLIENTE: {val.NOME_REDUZIDO || "-"}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className={style.dropdownContainer}>
            <select
              className={style.navioDropdown}
              value={selectedNavio}
              onChange={handleNavioChange}
            >
              <option value="">Selecione um Navio</option>
              {operacoesList.map((val, key) => (
                <option key={key} value={val.COD_OPERACAO}>
                  {val.NOME_NAVIO || "-"}
                </option>
              ))}
            </select>
            <div className={style.btnRelatorio}>
              <SubmitButton text={"Cadastrar"} onClick={handleDocViagemClick} />
            </div>
          </div>

        </div>
      </Container>

      <Dialog
        open={openA}
        onClose={FecharDetalhesOp}
        fullWidth
        maxWidth="lg" // ou use "md", "sm", "xl" dependendo do tamanho desejado
        PaperProps={{
          style: { // Estilos CSS personalizados
            maxHeight: '90vh', // altura máxima
            width: '80%', // largura personalizada
            // Adicione mais estilos aqui conforme necessário
          },
        }}
      >
        <div className={modal.modal}>
          <div className={modal.nav}>
            <Tabs value={activeTab} onChange={handleTabChange} centered>
              <Tab
                className={modal.customtab}
                label={<div onClick={FecharDetalhesOp}><i className="fa fa-arrow-left"></i> Voltar</div>}
                onClick={FecharDetalhesOp}
              />
              <Tab className={modal.customtab} label="Cargas" />
              <Tab className={modal.customtab} label="Pedidos" />
              <Tab className={modal.customtab} label="Transportadoras" />
            </Tabs>
          </div>

          <div className={modal.abas}>
            {activeTab === 0 && (
              <div>
              </div>
            )}

            {activeTab === 1 && (
              <div className={modal.confirmar}>
                <div className={modal.detalhesstatus}>
                  <div className={modal.status}>
                    <i className="fa fa-ship icon"></i>&nbsp;&nbsp;{i.STATUS_OPERACAO}
                  </div>
                  <div className={modal.navio}><b>Navio:</b> &nbsp;&nbsp;{i.NOME_NAVIO}</div>
                </div>

                <div className={modal.center}>
                  <div className={modal.cargas}>
                    <table className={modal.table}>
                      <caption>
                        <div className={modal.titulo}>
                          DI/BL
                        </div>
                      </caption>
                      <thead>
                        <tr>
                          <th>TIPO</th>
                          <th>NUMERO</th>
                          <th>IMPORTADOR</th>
                          <th>PRODUTO</th>
                          <th>MANIFESTADO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cargas.length === 0 ? (
                          <tr>
                            <td colSpan="6">Nenhuma carga identificada</td>
                          </tr>
                        ) : (
                          cargas.map((val) => (
                            <tr key={val.COD_CARGA}>
                              <td>{val.TIPO}</td>
                              <td>{val.NUMERO}</td>
                              <td>{val.IMPORTADOR}</td>
                              <td>{val.PRODUTO}</td>
                              <td>{val.QTDE_MANIFESTADA.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className={modal.center}>
                  {i.STATUS_OPERACAO === 'AGUARDANDO DI/BL' ? (
                    <button className={modal.finalizar}
                      onClick={() => navigate(`/cargas/cadastro/${i.NOME_NAVIO}/${i.COD_OPERACAO}`)}>
                      EDITAR CARGA
                    </button>
                  ) : (
                    <div className={modal.center}>Não é possível adicionar mais DI/Bl nesta operação</div>
                  )}

                  {i.STATUS_OPERACAO === 'AGUARDANDO O INÍCIO DA OPERAÇÃO' ? (
                    <button className={modal.finalizar}
                      onClick={AbrirConfirm}>
                      INICIAR A OPERAÇÃO
                    </button>
                  ) : ""}
                </div>
              </div>
            )}

            {activeTab === 2 && (
              <div className={modal.confirmar}>
                <div className={modal.navioNome}>
                  <i className={`fas fa-ship ${modal.navioIcone}`}></i>
                  <div><b>Navio:</b> {i.NOME_NAVIO}</div>
                </div>

                <form onSubmit={handlePedidoSubmit}>
                  <div className={modal.formRow}> {/* Contêiner pai com Flexbox */}
                    <div className={modal.formColumn}> {/* Primeira coluna */}
                      <label htmlFor="cargasDropdown">DI ou BL</label>
                      <select id="cargasDropdown" value={selectedCarga} onChange={handleCargaChange}>
                        <option value="">Selecione a DI</option> {/* Opção inicial para incentivar a seleção */}
                        {cargas.map((carga) => (
                          <option key={carga.COD_CARGA} value={carga.COD_CARGA}>
                            {carga.NUMERO}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className={modal.importadorDisplay}> {/* Coluna para exibir o Importador da carga selecionada */}
                      <label>Importador:</label>
                      <div>{selectedImportador || ''}</div>
                    </div>

                    <div className={modal.formColumn}> {/* Segunda coluna */}
                      <label>PEDIDO</label>
                      <input
                        type="text"
                        id="nrPedido"
                        value={nrPedido}
                        onChange={(e) => setNrPedido(e.target.value)}
                      />
                    </div>

                    <div className={modal.formColumn}>
                      <label htmlFor="destinoSelect">DESTINO</label>
                      <select
                        id="destinoSelect"
                        value={selectedDestino}
                        onChange={(e) => setSelectedDestino(e.target.value)}
                        className={modal.selectDropdown} // Assegure-se de que esta classe esteja definida em seu CSS
                      >
                        <option disabled selected>Selecione um destino</option>
                        {destinos.map((destino) => (
                          <option key={destino.COD_DESTINO} value={destino.COD_DESTINO}>
                            {destino.NOME_DESTINO}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className={modal.formColumn}>
              <label>TIPO DE OPERAÇÃO</label>
              <select 
              id="TipoOperacao"
              value={selectedTipoOperacao}
              onChange={(e) => setSelectedTipoOperacao(e.target.value)}
              className={modal.selectDropdown}
              >
                <option disabled selected>Selecione</option>
                <option value="ENTRADA">ENTRADA</option>
                <option value="SAÍDA">SAÍDA</option>
              </select>
            </div>
                  </div>

                  {showPedidoData && (
                    <div className={modal.tableContainer}>
                      <table className={modal.table}>
                        <thead>
                          <tr>
                            <th>DI ou BL</th>
                            <th>Pedido</th>
                            <th>Destino</th>
                            <th>Tipo de Operação</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pedidoData.map((pedido, index) => (
                            <tr key={index}>
                              <td>{pedido.NUMERO_DOC}</td>
                              <td>{pedido.NR_PEDIDO}</td>
                              <td>{pedido.NOME_DESTINO}</td>
                              <td>{pedido.TIPO_OPERACAO}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <button type="submit" className={modal.finalizar}>
                    ADICIONAR PEDIDO
                  </button>
                </form>
              </div>
            )}

            {activeTab === 3 && (
              <div className={modal.confirmar}>
                <div className={modal.navioNome}>
                  <i className={`fas fa-ship ${modal.navioIcone}`}></i>
                  <div><b>Navio:</b> {i.NOME_NAVIO}</div>
                </div>

                <form onSubmit={handleTransportadoraSubmit}>
                  <div className={modal.formRow}>
                    <div className={modal.formColumn}>
                      <label htmlFor="cargasDropdown">DI ou BL</label>
                      <select id="cargasDropdown" value={selectedCarga} onChange={handleCargaChange}>
                        <option value="">Selecione uma carga</option>
                        {cargas.map((carga) => (
                          <option key={carga.COD_CARGA} value={carga.COD_CARGA}>{carga.NUMERO}</option>
                        ))}
                      </select>
                    </div>

                    <div className={modal.formColumn}>
                      <label htmlFor="pedidoSelect">Pedido</label>
                      <select id="pedidoSelect" value={selectedPedido} onChange={handlePedidoChange}>
                        <option value="">Selecione um pedido</option>
                        {pedidosPorCarga.map((pedido) => (
                          <option key={pedido.ID_PEDIDO} value={pedido.ID_PEDIDO}>{pedido.NR_PEDIDO}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className={modal.formColumnEmpresa}>
                    <label>Transportadoras</label>
                    <div className={modal.formColumnEmpresa}>
                      {transportadoras.map((transp) => (
                        <div key={transp.COD_TRANSP}>
                          <input
                            type="checkbox"
                            id={`transp-${transp.COD_TRANSP}`}
                            checked={selectedTransportadoras.includes(transp.COD_TRANSP)}
                            onChange={() => handleTransportadoraChange(transp.COD_TRANSP)}
                          />
                          <label htmlFor={`transp-${transp.COD_TRANSP}`}>{transp.NOME_REDUZIDO_TRANSP}</label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className={modal.tableContainer}>
                    <table className={modal.table}>
                      <thead>
                        <tr>
                          <th>DI ou BL</th>
                          <th>Pedido</th>
                          <th>Transportadora</th>
                          <th>CNPJ Transportadora</th>
                        </tr>
                      </thead>
                      <tbody>
                        {showTransportadoraData ? (
                          transportadorasPorPedido.length > 0 ? (
                            transportadorasPorPedido.map((transportadora, index) => (
                              <tr key={index}>
                                <td>{transportadora.NUMERO_DOC}</td>
                                <td>{transportadora.NR_PEDIDO}</td>
                                <td>{transportadora.NOME_TRANSP}</td>
                                <td>{formatarCNPJ(transportadora.CNPJ_TRANSP)}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">Nenhuma transportadora encontrada para este pedido</td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan="4">Não há transportadoras cadastradas neste pedido.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <button type="submit" className={modal.finalizar}>
                    ADICIONAR TRANSPORTADORA
                  </button>
                </form>
              </div>
            )}


          </div>
        </div>
      </Dialog>

      <Confirm open={openB} onClose={FecharConfirm} fullWidth>
        <div className={confirm.modal}>
          <div className={confirm.nav}>
            <div className={confirm.navbar} onClick={FecharConfirm}>Voltar</div>
          </div>
          <div className={confirm.center}>
            Deseja iniciar a Operação?
            <br />
            <div>Ao confirmar o Dashboard será liberado!</div>
          </div>

          <div className={confirm.flex}>
            <button className={confirm.cancelar} onClick={FecharConfirm}>CANCELAR</button>
            <button className={confirm.confirmar} onClick={registrarAtracacao}>CONFIRMAR</button>
          </div>
        </div>
      </Confirm>

    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}>
      <Cargas />
    </SnackbarProvider>
  );
}
