import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./Transportadora.module.css";
import Input from "../../../components/Input";
import SubmitButton from "../../../components/Button";
import Axios from "axios";
import InputMask from "react-input-mask";
import { useNavigate } from 'react-router-dom';

import { SnackbarProvider, useSnackbar } from "notistack";

const Transportadora = () => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [nomeTransportadora, setNomeTransportadora] = useState("");
  const [cnpjTransportadora, setCNPJTransportadora] = useState("");
  const [nomeReduzidoTransp, setNomeReduzidoTransp] = useState("");
  const [grupoTransportadora, setGrupoTransportadora] = useState("");
  const [transportadorasLista, setTransportadorasLista] = useState([]);

  // Função para mostrar alertas
  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant: variant });
  };

  // Função para enviar dados do cliente
  const cadastrarTransportadora = async () => {
    if (!nomeTransportadora || !cnpjTransportadora || !nomeReduzidoTransp || !grupoTransportadora) {
      showAlert("Preencha todos os campos", "error");
      return;
    }
  
    if (!validarCNPJ(cnpjTransportadora)) {
      showAlert("CNPJ inválido", "error");
      return;
    }
  
    try {
      const response = await Axios.post(
        "https://portal.jc7transportes.com.br/api/transportadora/cadastrar",
        {
          nome_transportadora: nomeTransportadora,
          cnpj_transportadora: cnpjTransportadora,
          nome_reduzido_transp: nomeReduzidoTransp,
          grupo_transportadora: grupoTransportadora,
        }
      );
  
      showAlert("Transportadora cadastrada com sucesso!", "success");
      // Aguarde 2 segundos (2000 milissegundos) antes de recarregar a página
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Tempo em milissegundos (2 segundos neste exemplo)
    } catch (error) {
      console.error("Erro ao cadastrar transportadora:", error);
      showAlert("Erro ao cadastrar transportadora", "error");
    }
  };
  

  const formatarCNPJ = (cnpj) => {
    if (cnpj.length !== 14) return cnpj; // Retorna o CNPJ sem formatação se não tiver 14 dígitos
  
    return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(12, 14)}`;
  };

  useEffect(() => {
    Axios.get("https://portal.jc7transportes.com.br/api/transportadora")
      .then((response) => {
        setTransportadorasLista(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados: ", error);
        showAlert("Erro ao buscar dados", "error");
      });
  }, []);

  function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g,''); // Remove todos os caracteres não numéricos
  
    if (cnpj.length !== 14) return false; // O CNPJ deve ter 14 dígitos
  
    // Verificação dos dígitos verificadores
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0,tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    
    if (resultado != digitos.charAt(0)) return false;
    
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    
    if (resultado != digitos.charAt(1)) return false;
  
    return true; // CNPJ é válido
  }
  

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
        <div className={style.nav}>
                    <div className={style.navbar} onClick={() => navigate("/administrador")}>
                            Administrador
                        </div>
                        <div className={style.active}>
                            Transportadora +
                        </div>
                        <div className={style.navbar} onClick={() => navigate("/AdministradorPage/Cliente")}>
                            Cliente +
                        </div>
                        <div className={style.navbar} onClick={() => navigate("/AdministradorPage/BigBag")}>
                            Big Bag +
                        </div>
                        <div className={style.navbar} onClick={() => navigate("/AdministradorPage/Destino")}>
                            Destino +
                        </div>
                    </div>
          <div className={style.columns}>
            <div className={style.nome}>
              <label>TRANSPORTADORA</label>
              <input
                type="text"
                id="nomeMotivado"
                onChange={(e) => setNomeTransportadora(e.target.value.toUpperCase())}
              />
            </div>

            <div className={style.cnpj}>
              <label>CNPJ</label>
              <InputMask
                mask="99.999.999/9999-99"
                placeholder="00.000.000/0000-00"
                value={cnpjTransportadora}
                onChange={(e) => {
                  const cnpj = e.target.value.replace(/[^\d]/g, "");
                  setCNPJTransportadora(cnpj);
                }}
              />
            </div>

            <div className={style.NomeReduzido}>
              <label>NOME REDUZIDO</label>
              <input
                type="text"
                id="nomeMotivado"
                onChange={(e) => setNomeReduzidoTransp(e.target.value.toUpperCase())}
              />
            </div>

            <div className={style.NomeGrupo}>
              <label>GRUPO</label>
              <input
                type="text"
                id="nomeMotivado"
                onChange={(e) => setGrupoTransportadora(e.target.value.toUpperCase())}
              />
            </div>

            <div className={style.submitButton}>
              <SubmitButton text="Cadastrar" onClick={cadastrarTransportadora} />
            </div>
          </div>

          <table className={style.table}>
            <thead>
              <tr>
                <th>Transportadora</th>
                <th>CNPJ</th>
                <th>Nome Reduzido</th>
                <th>Grupo</th>
              </tr>
            </thead>
            <tbody>
              {transportadorasLista.map((transportadora, index) => (
                <tr key={index}>
                  <td>{transportadora.NOME_TRANSP}</td>
                  <td>{formatarCNPJ(transportadora.CNPJ_TRANSP)}</td>
                  <td>{transportadora.NOME_REDUZIDO_TRANSP}</td>
                  <td>{transportadora.GRUPO_TRANSPORTADORA || ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <Transportadora />
    </SnackbarProvider>
  );
}
