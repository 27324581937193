import React, { useEffect } from "react";
import { useState } from "react";
import Axios from 'axios';
import Navbar from "../../components/Navbar";
import Brackground from "../../components/Background";
import Container from "../../components/Container";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import style from "./Dash.module.css";
import GerarNfe from "../../components/GerarNfe";
import BarChart from "../../components/Barchart/Barchart";



const Dashboard = () => {
  const { id } = useParams()

  return (
    <>
      <Navbar />
      <Header />
      <Brackground />
      <Container>
      <div className={style.centerLogo}>

</div>
 <br />
      </Container>
    </>
  );
};

export default Dashboard;
