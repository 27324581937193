import React, { useState } from "react";
import { Link } from "react-router-dom";
import style from "./Header.module.css";
import JC7Logo from "./Logo-JC7.png"; 


function Header() {
  const userToken = localStorage.getItem("user_token");
  const user = JSON.parse(userToken);

  return (
    <div className={style.header}>
      <div className={style.conteudo}>
      <img src={JC7Logo} alt="Logo" className={style.logo} /> {/* Inclua esta linha */}
      <div className={style.nome}>{user.nome} - {user.departamento}</div>
    </div>
    </div>
  );
}


export default Header;
